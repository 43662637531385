import React from "react";

import "./Player.css";

import CheatMSGBody from "./CheatMSGBody";

// import Footer from "./Footer";

function Player({ spotify , suchbegriff, searchResults}) {



  return (
    <div className="player">
      <div className="player__body">

        <CheatMSGBody spotify={spotify}/>
      </div>
    
    </div>
  );



}
export default Player;
