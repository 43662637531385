import React, { useState, useEffect } from 'react';
import SongBoxSponsored from "./SongBoxSponsored";
import "./SponsoredBoxes.css";

function ShowSponsoredBoxes({deliveredBoxes, spotify}) {

  //  DIESES FILE IST SPONSORED SLIDE
//Auswahl der SPONSORED SONGS nach 5 Songs
                                 //edgy - i love        // can't stop           //always now         
    const auswahlSong1 = ["556Gwxjv6xukdxSQ4Zq0Wt", "0x9GmQKRRAvqup2IgVgWRO", "71bshjUlN5v2QdCrQo0lYJ"];

    


    const [randomSongIndex, setrandomSongIndex] = useState(
        //inital value
          null
     ); //hook example


     const [randomSongChosen, setrandomSongChosen] = useState(
        //inital value
          0
     ); //hook example


    useEffect(() => {

    

        setrandomSongIndex(
            Math.floor(Math.random()*auswahlSong1.length));
    
            setrandomSongChosen(
                1);
         
    
            
            
      }, [] );   



        //falls noch kein random song
      if(randomSongChosen==0) {

        return (
            <div>
                <p>Waiting for last song</p>
            </div>
        )

      }





      if(randomSongChosen==1) { // if randomSong chosenStart ÜBER GANZE FUNKTION
        const song1id = auswahlSong1[randomSongIndex]; //random von uswahlSong1
     
        // NICHT AKTIV IN DIESEM FILE !! DIESES FILE IST SPONSORED SLIDE
  
    const song2id = "1OoJs5raYud9Vc0f9a07Gu"; // GON-Player
    const song3id = "6ETkpphUYM6ztjWU8XOz34"; // Làlà bizzli sehnsucht
    const song4id = "3QdKpOI925EZX7VfxqW2pn"; // GON-Real You 2020
    const song5id = "3Rjzn6UfUDTjISOv61lBT6"; // làlà - vain desires
  
    let trackList=[];

    //decide amount of sponsored boxes to show -> case says how many are delivered from firebase
    switch (deliveredBoxes) {
        case 5:
            trackList=[];
            break;
        case 4:
            trackList=[song1id];
            break;
        case 3:
            trackList=[song1id,song2id];
            break;
        case 2:
            trackList=[song1id,song2id,song3id];
            break;
        case 1:
            trackList=[song1id,song2id,song3id,song4id];
            break;
        case 0:
            trackList=[song1id,song2id,song3id,song4id,song5id];
            break;
        default:
            trackList=[song1id];
            break;
    }


 
 
 


    return (
        <div className="sponsoredBoxes">
    
     {/* <p>
   Boxes to show: {sponsoredBoxesToShow}
   </p>
   // For loop array */}
{
   trackList && trackList.map(tracknamehierdefiniert_proelement => {

  return <SongBoxSponsored trackIdSponsored={tracknamehierdefiniert_proelement} spotify={spotify}/>
 })


}
 
 
        </div>
    )
}

} // if randomSong chosenStart end

export default ShowSponsoredBoxes
