//DB WRITE PASSIERT AUF EBENE MeldungSongGeAdded.js

import React, { useState, useEffect } from 'react';
import "../Faq.css";
// import FeedbackCarousel from "./FeedbackCarousel/FeedbackCarousel";

import { useStateValue } from "../StateProvider";
// import Timer from "./Timer";
// import SongRow from "./SongRow";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { testTrackThree } from "./testtrack";


// import { AlternateEmail } from "@material-ui/icons";
// import TrackResult from "./TrackResult";
//import SearchResults from "./jammming-src/Components/SearchResults/SearchResults";
//import TrackList from "./jammming-src/Components/TrackList/TrackList";
//import Track from "./jammming-src/Components/Track/Track";

// war auf 3
const timeUntilNoMoreChecks=3;

function MeldungSongGeAddedBody() {
const [{NameOfAddedSong, showButtonBackToCarousel, slideNrToShowToUser}, dispatch] = useStateValue();
const [counter, setCounter] = useState(timeUntilNoMoreChecks);


// Bei jedem counter change
useEffect(() => {

  counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

  //passiert jede sekunde bis zeit abgelaufen

  // automatisch zurück wenn User etwas abspielt!!!

  


      if (counter==0) {
         // console.log("User is currently playing something!");
      

      
         backToCarousel();
      } 


}, [counter]);



function backToCarousel() {




    //CHEATCHECK SONG ZU AUSGANGSLAGE START


          dispatch({
            type: "SET_INFOSONGPLAYING",
            infoSongPlaying:
          
            //ID WICHTIG FUER CHEATUEBERWACHUNG
          {
          
          
            "is_playing": false, //wichtig wegen cheating
          
                 "item": {
          
           
          
                "id": "ischeating",  //WICHTIG FUER CHEATUEBERWACHUNG
                "name": "check is cheating",
          
                }
          },
          
          
          }) //song cheating chekck zu ausgangslage ende
          

          //CHEATCHECK SONG ZU AUSGANGSLAGE ENDE



          
          dispatch({
            type: "SET_PLAYBLOCKER",
            playBlocker: null,

          })

          dispatch({
            type: "SET_DBLESENCOMINGMENU",
            dblesencomingfrommenu:1,
          });



          dispatch({
            type: "SET_SHOWBUTTONBACKTOCAROUSEL",
            showButtonBackToCarousel: 1,
    
          });

 // song to add zurück setzen
 dispatch({
  type: "SET_SONGTOADDCHOSEN",
  songToAddChosen: 0,
    });


// ERSTES MAL slide ist auf 1


// -> Passiert nicht beim 1ten mal
   if (slideNrToShowToUser===2) {

    dispatch({
      type: "SET_SLIDENRTOSHOWTOUSER",
      slideNrToShowToUser: 3,
 
     });

    };
// -> Passiert beim 1ten mal

if (slideNrToShowToUser===1) {

    dispatch({
      type: "SET_SLIDENRTOSHOWTOUSER",
      slideNrToShowToUser: 2,
 
     });

    };


    
        
    //LADEN DER SONG HINZUFÜGEN MASKE, WENN GENUG SONGS GERATED
   //ChooseSong.js wird geladen
      dispatch({
        type: "SET_MENUSTATE",
        menustate: "feedbackcarouselmenu",
      });

 //   token && menustate==="feedbackcarouselmenu" && <FeedbackCarousel spotify={spotify}/>



}

  return (


    <div className="body">
       
     

      <div className="song__thatWillBeAdded">
        <h1>Success🎉! Your song: "{NameOfAddedSong}" is added!</h1>
        <p>&nbsp;</p>
        
    
    {showButtonBackToCarousel==1 && 
    
        <div className="takeMeBack" onClick={backToCarousel} >
        <h2><a href="#">Take me to the Song Carousel so I can play and rate more songs</a></h2>
                </div>}

  
                

   

            </div>
            

     


    </div>
  );
}

export default MeldungSongGeAddedBody;
