

import React from "react";
import json2mq from 'json2mq';
import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import { Logo } from "../logo";
import { Accessibility } from "./accessibility";
import { NavLinks } from "./navLinks";
import { DeviceSize } from "../responsive";
import { MobileNavLinks } from "./mobileNavLinks";

const NavbarContainer = styled.div`
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  display: flex;
  align-items: center;
  padding: 0 1.5em;
  background-color: rgb(240, 240, 240);

`;

const LeftSection = styled.div`
display: flex;
`;

const MiddleSection = styled.div`
display: flex;
flex: 2;
height: 100%;
justify-content: center;
`;

const RightSection = styled.div`
display: flex;
`;


export function NavBar(props) {
    const isMobile = useMediaQuery( json2mq({ maxWidth: DeviceSize.mobile}))

    return (
        <NavbarContainer>
          <LeftSection>
        
          {!isMobile && <Accessibility />}
            {isMobile && <MobileNavLinks />}
            <Logo />
          </LeftSection>
          <MiddleSection>{!isMobile && <NavLinks />}</MiddleSection>
          <RightSection>

          </RightSection>
        </NavbarContainer>
      );
    }


 