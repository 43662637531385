//DB WRITE PASSIERT HIER


import React, { useState, useEffect } from 'react';

import "../Player.css";

import MeldungSongGeAddedBody from "./MeldungSongGeAddedBody";
import { useStateValue } from "../StateProvider";

//FIREBASE HIER FUER DB WRITE
// auth für login
import { firestore, auth } from '../firebase';


//alles FireBase Import Start

import firebase from 'firebase/app';

import { useAuthState } from 'react-firebase-hooks/auth';



function MeldungSongGeadded() {
  const [{trackIdSongToAdd, fromSpotifyUserSongToAdd, fromSpotifyUserSongToAddEmail, dbTrackSelection, dbUserRatings, NameOfAddedSong}, dispatch] = useStateValue();

//INITIAL VALUE CURRENTRATINGS -> kommt aus DB bestehend
const [currentRatings, setCurrentRatings] = useState(
//inital value
null
); //hook example       

const [onlySetAllUploadedOnce, setOnlySetAllUploadedOnce] = useState(
  //inital value
  false
  ); //hook example    

//INITIAL VALUE COUNTDOWN ZEIGEN -> was ge-added wird
const [ratingsToAdd, setRatingsToAdd] = useState(
 //inital value
null
); //hook example      


  //INITIAL VALUE COUNTDOWN ZEIGEN -> was ge-added wird
  const [meldungDBCountingsFetched, setMeldungDBCountingsFetched] = useState(
    //inital value
     0


); //hook example     

  //INITIAL VALUE COUNTDOWN ZEIGEN -> was ge-added wird






  getCurrentRatings()






  useEffect(() => {

    //ANMELDEN BEI DB START
          const provider = new firebase.auth.GoogleAuthProvider();
          //KEINE PARAMETER
          auth.signInAnonymously();
          //FIREBASE ANONYM LOGIN END
    //ANMELDEN BEI DB END


//promise ist 
// *! GET Current Ratings /-/ START




// *! GET Current Ratings /-/ END


                 //PROMISE -  START RATINGS GEHOLT 

           


                  if (ratingsToAdd<=0) {

         

                  

// erst wenn sie geändert sind anpassen!
        




             //reference to firestore collection ! UM REINSCHREIBEN ZU KÖNNEN
const tracksToRateRef = firestore.collection(dbTrackSelection);



            //IN DB SCHREIBEN TEIL 1 START

            // https://stackoverflow.com/questions/60940124/firebase-firestore-wait-for-update-to-complete-js-web
            tracksToRateRef.doc(trackIdSongToAdd+"|Spot-User-Id:"+fromSpotifyUserSongToAdd).set({


      
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              songName: NameOfAddedSong,
              trackid: trackIdSongToAdd,
              fromspotifyuser: fromSpotifyUserSongToAdd,
              fromspotuserArr: firebase.firestore.FieldValue.arrayUnion(fromSpotifyUserSongToAdd),
              fromSpotUserEmail: fromSpotifyUserSongToAddEmail, // benötigt um bei Ratings abfragen zu können zu welcher email
              countRatings: ratingsToAdd //calulation happens at firestore!!

  
              });




    //IN DB SCHREIBEN TEIL 1 END


                            //IN_DB_SCHREIBEN USER RATINGS START
      
                            //DIES IST REF:
                            const songRatingsRef = firestore.collection(dbUserRatings);
    
                                      //FIREBASE ANONYM LOGIN START

                                                      //IST UPDATE BEI RATING
                              songRatingsRef.doc(fromSpotifyUserSongToAdd+"-"+trackIdSongToAdd).set({
                              
                              ratingdates: firebase.firestore.FieldValue.arrayUnion(),

                              tracknames: firebase.firestore.FieldValue.arrayUnion(),

                              ratingscores: firebase.firestore.FieldValue.arrayUnion(),

                              ratingBoxIdentifier: fromSpotifyUserSongToAdd+"-"+trackIdSongToAdd

                         
                  
                              }, {merge: true});

               //UserRatinPool SET Song Ende      
                         //IN_DB_SCHREIBEN USER RATINGS ENDE

                 //IN_DB_SCHREIBEN ENTITLED RATINGS PER SONG PER USER - Start

              



                        const songEntitledRatingsRef = firestore.collection("entitledRatingsPerSongPerUser");
    
                

                                        //IST UPDATE BEI RATING
                songEntitledRatingsRef.doc(trackIdSongToAdd+"|Spot-User-Id:"+fromSpotifyUserSongToAdd).set({

                  createdAtUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),

                  songName: NameOfAddedSong,
              
                  trackIdOfSong: trackIdSongToAdd,
                  entitledRatings: ratingsToAdd*-1+5, //*+1 +5 sorgt dafür dass korrekte anzahl zu erhaltende Ratings angezeigt werden!
                  fromspotifyuser: fromSpotifyUserSongToAdd,
                  

    
                }, {merge: true}); //damit ratings hochgezählt werden!!



                     //IN_DB_SCHREIBEN ENTITLED RATINGS PER SONG PER USER - Ende
            
                              //DB ALL UPLOADED SONGS START
                              // CREATE DATE
                              const trackunique = Date.now();
                              const uploadDate = trackunique;
                              const ratingDateToString = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(uploadDate);


                              const allUploadedSongsRef = firestore.collection("allUploadedSongs");

                              if (onlySetAllUploadedOnce===false) {
                              allUploadedSongsRef.doc("allUploadedSongs").update({
                                
                               

                                                                                    // date,                    spotUserId,                 spotUserEmail,                  artistName-Songname, SpotSongId
                                allinfo: firebase.firestore.FieldValue.arrayUnion(ratingDateToString+"|Spot-User-Id:"+fromSpotifyUserSongToAdd+"|Spot-User-Email:"+fromSpotifyUserSongToAddEmail+"|"+NameOfAddedSong+"|Spot-Track-Id:"+trackIdSongToAdd)


                                
                 
                              });








                              setOnlySetAllUploadedOnce(true);

                            };
                               //DB ALL UPLOADED SONGS END

                            

                              







                                        //then end
                };



                  //PROMISE -  ENDE RATINGS GEHOLT 

  }, [currentRatings, ratingsToAdd]);

// Anonymous LOGIN firebaese
const [userfirebase] = useAuthState(auth);  //if User is logged-in: user is an Object // signed out: User is null

async function getCurrentRatings() {

    // setCurrentRatings(-100);


   


 await firestore.collection(dbTrackSelection).doc(trackIdSongToAdd+"|Spot-User-Id:"+fromSpotifyUserSongToAdd).get()
    
  // .then(snapshot => setCurrentRatings(snapshot.data().countRatings));
     .then(snapshot => 
      {
        if (snapshot.data() === undefined) {
          setCurrentRatings(5);

        } else {
          setCurrentRatings(snapshot.data().countRatings);


        
        }


      }
      
      
      ) // then end


    }



    const AreRatingsReceivedHere = new Promise((resolve, reject) => {
      if (currentRatings!=null && meldungDBCountingsFetched==0) {
        resolve()
      } else {
        reject('Failed')
      }
    
    })
  
  
    AreRatingsReceivedHere.then(() => {
  
    // Gib Meldung an useEffect

    setRatingsToAdd( currentRatings-5 );
      setMeldungDBCountingsFetched(1);

      dispatch({
        type: "SET_SHOWBUTTONBACKTOCAROUSEL",
        showButtonBackToCarousel: 1,

      });
          

      

  
    }).catch((message) => {
                  
      console.log('this is the catch ' + message);
    
    });
  




  return (
    <div className="player">
      <div className="player__body">
 
        <MeldungSongGeAddedBody />
      </div>
    
    </div>
  );



}
export default MeldungSongGeadded;
