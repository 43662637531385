import React from "react";
import "./Body.css";
import FeedbackCarousel from "./FeedbackCarousel/FeedbackCarousel";
import Header from "./Header";
import { useStateValue } from "./StateProvider";
// import Timer from "./Timer";
// import SongRow from "./SongRow";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { testTrackThree } from "./testtrack";


// import { AlternateEmail } from "@material-ui/icons";
// import TrackResult from "./TrackResult";
//import SearchResults from "./jammming-src/Components/SearchResults/SearchResults";
//import TrackList from "./jammming-src/Components/TrackList/TrackList";
//import Track from "./jammming-src/Components/Track/Track";

function CheatMSGBody({ spotify }) {
const [{ token, menustate }, dispatch] = useStateValue();

function backToCarousel() {



//AUTOPLAY FOOTER EINSCHALTEN
dispatch({
  type: "SET_AUTOPLAYFOOTER",
  showAutoPlayFooter: false,
   });








    //CHEATCHECK SONG ZU AUSGANGSLAGE START

    

          //song cheating chekck zu ausgangslage start
        
        
          dispatch({
            type: "SET_INFOSONGPLAYING",
            infoSongPlaying:
          
            //ID WICHTIG FUER CHEATUEBERWACHUNG
          {
          
          
            "is_playing": false, //wichtig wegen cheating
          
                 "item": {
          
           
          
                "id": "ischeating",  //WICHTIG FUER CHEATUEBERWACHUNG
                "name": "check is cheating",
          
                }
          },
          
          
          }) //song cheating chekck zu ausgangslage ende
          
       
  
          //CHEATCHECK SONG ZU AUSGANGSLAGE ENDE


          
          dispatch({
            type: "SET_PLAYBLOCKER",
            playBlocker: null,

          })



          dispatch({
            type: "SET_DBLESENCOMINGMENU",
            dblesencomingfrommenu:1,
          });


 


   
    //LADEN DER SONG HINZUFÜGEN MASKE, WENN GENUG SONGS GERATED
   //ChooseSong.js wird geladen
      dispatch({
        type: "SET_MENUSTATE",
        menustate: "feedbackcarouselmenu",
      });

   if (token && menustate==="feedbackcarouselmenu") return <FeedbackCarousel spotify={spotify}/>




}

  return (


    <div className="body">
       
      <Header spotify={spotify} />

      <div className="song__thatWillBeAdded">
        <h1>Error 👻</h1>
        <h2>
        <p>&nbsp;</p>
            <p>
            You either paused the song that needs rating or played another song on one of your devices.<br />
            Or something else went wrong.</p>
            <p>&nbsp;</p>
            <p>⛔ Please do not pause songs from the Song Carousel on other Devices (also after rating them). <br />Just play another song from the Song Carousel until you have rated enough songs to add your own.</p>
            <p>&nbsp;</p>
            <p>⛔ Please do not play other songs as long as you use the Song Carousel.</p>
            <p>&nbsp;</p>
            <p>⛔ Please make sure you are not in private mode while playing Songs and that you have a Premium Spotify Account.</p>
            <p>&nbsp;</p>
        </h2>
        <div className="takeMeBack" onClick={backToCarousel} >
        <h2><a href="#">Take me Back to the Song Carousel</a></h2></div>
   

            </div>
            

     


    </div>
  );
}

export default CheatMSGBody;
