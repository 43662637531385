import React from "react";
import "./Faq.css";
import FeedbackCarousel from "./FeedbackCarousel/FeedbackCarousel";
import Header from "./Header";
import { useStateValue } from "./StateProvider";
// import Timer from "./Timer";
// import SongRow from "./SongRow";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { testTrackThree } from "./testtrack";


// import { AlternateEmail } from "@material-ui/icons";
// import TrackResult from "./TrackResult";
//import SearchResults from "./jammming-src/Components/SearchResults/SearchResults";
//import TrackList from "./jammming-src/Components/TrackList/TrackList";
//import Track from "./jammming-src/Components/Track/Track";

function FaqSlideBody ({ spotify }) {
const [{ token, menustate }, dispatch] = useStateValue();

function backToCarousel() {






//AUTOPLAY FOOTER AUSSCHALTEN
dispatch({
  type: "SET_AUTOPLAYFOOTER",
  showAutoPlayFooter: false,
   });





    //CHEATCHECK SONG ZU AUSGANGSLAGE START

    

          //song cheating chekck zu ausgangslage start
        
        
          dispatch({
            type: "SET_INFOSONGPLAYING",
            infoSongPlaying:
          
            //ID WICHTIG FUER CHEATUEBERWACHUNG
          {
          
          
            "is_playing": false, //wichtig wegen cheating
          
                 "item": {
          
           
          
                "id": "ischeating",  //WICHTIG FUER CHEATUEBERWACHUNG
                "name": "check is cheating",
          
                }
          },
          
          
          }) //song cheating chekck zu ausgangslage ende
          
       
  
          //CHEATCHECK SONG ZU AUSGANGSLAGE ENDE

          dispatch({
              type: "SET_AUTOPLAYSONG",
              autoPlaySong: "false",
          })

          
          dispatch({
            type: "SET_PLAYBLOCKER",
            playBlocker: null,

          })


          dispatch({
            type: "SET_DBLESENCOMINGMENU",
            dblesencomingfrommenu:1,
          });
 

   
    //LADEN DER SONG HINZUFÜGEN MASKE, WENN GENUG SONGS GERATED
   //ChooseSong.js wird geladen
      dispatch({
        type: "SET_MENUSTATE",
        menustate: "feedbackcarouselmenu",
      });

    
  

   if (token && menustate==="feedbackcarouselmenu") return <FeedbackCarousel spotify={spotify}/>


}


  return (


    <div className="body">
       
      <Header spotify={spotify} />

      <div className="song__thatWillBeAdded">
        <h1>FAQ 🙋🙋🏽🙋🏻</h1>
        
        <h2>

        <p>&nbsp;</p>

            
        <p>1) What is Song Carousel?</p>
            <p>&nbsp;</p>
            <div className="answer"><p>Song Carousel is a Platfrom where Musicians who have songs on Spotify can get free plays and feedback on their songs.</p></div>
            <p>&nbsp;</p>
            
            <p>2) Is Song Carousel free to use?</p>
            <p>&nbsp;</p>
            <div className="answer"><p>Yes absolutely.</p></div>
            <p>&nbsp;</p>
            <p>3) How can I add a song to the Song Carousel?</p>
            <p>&nbsp;</p>
            <div className="answer"><p>Listen and rate 5 songs and you will be taken to a form where you can add your song.</p></div>
            <p>&nbsp;</p>

            <p>4) Can other Users see what ratings I gave?</p>
            <p>&nbsp;</p>
            <div className="answer"><p>No, they just see the rating itself, not who gave it. But please still be fair and honest when giving ratings.</p></div>
            <p>&nbsp;</p>

            <p>5) Can others see what ratings I have received?</p>
            <p>&nbsp;</p>
            <div className="answer"><p>No, only YOU see the ratings your receive. </p></div>
            <p>&nbsp;</p>


            <p>6) What is required to use the Song Carousel</p>
            <p>&nbsp;</p>
            <div className="answer"><p>You need a Spotify Premium Account in order to use it.</p></div>
            <p>&nbsp;</p>
     

                <p>
                    7) Is Song Carousel related to Spotify? </p>
                 <p>&nbsp;</p>
                 <div className="answer"><p>No. It is not related to Spotify. It is just an Application that uses the Spotify API.</p></div>
                    <p>&nbsp;</p>

                    <p>
                    8) Will I see my own Songs in my Song Carousel? </p>
                 <p>&nbsp;</p>



 
 
                 <div className="answer"><p>No, you will never see your own Songs in your Song Carousel. Only other Users see your Song in their Carousel.<br />
                This way you will get real plays from others on your song.</p></div>
            <p>&nbsp;</p>

            <p>9) How many plays/ratings will get per song upload</p>
            <p>&nbsp;</p>
            <div className="answer"><p>Each Song will receive at least 5 plays/ratings per Upload.</p></div>
            <p>&nbsp;</p>
    
            
            <p>10) Can I upload the same song several time so it will get more plays.</p>
            <p>&nbsp;</p>
            <div className="answer"><p>Yes absolutely. If you upload the same song several times it will receive more plays/ratings (eg. Song xy gets uploaded 3 times: It will receive at least 15 plays/ratings).</p></div>
            <p>&nbsp;</p>








        </h2>
                
                <div className="takeMeBack" onClick={backToCarousel} >
                <h2><a href="#">Take me Back to the Song Carousel</a></h2>
                </div>
   

            </div>
            

     


    </div>
  );
}

export default FaqSlideBody;
