import React, { useState, useEffect } from 'react';
// import SpotifyWebApi from "spotify-web-api-js";
import "./SponsFeedbackBody.css";
import Header from "../Header";
import { useStateValue } from "../StateProvider";
import SongBox from "./SongBoxes/SongBox";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FBGetSongsFromDB from "./SponsFBGetSongsFromDB.js";
// import { testTracksToGetRated } from "./testDataSelectionOfUnratedTracks";
import "../AddMySong/TrackResult.css";
// import SpotifyJam from "./SpotifyJam";
// import PlaylistListTrackResults from "./PlaylistTrackResults"
// import { testTracksRatings } from "./testDataStoreRatings";
// import { testSongsAddedByUser } from "./testDataSongAddedByUser";
// import SongBoxSponsored from './SongBoxSponsored';
import ChooseSong from "../AddMySong/ChooseSong";
import firebase from 'firebase/app';
import { auth } from '../firebase';
// import { useAuthState } from 'react-firebase-hooks/auth';
// const s = new SpotifyWebApi(); //nötig für menuwechsel

//alles FireBase Import Start
import ShowSponsoredBoxes from "./SongBoxes/ShowSponsoredBoxes";
// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import SpotLogoLogin from "../img/Spotify_Logo_Login.png"
// au


function FeedbackCarouselBodyUserFetched({spotify, userid}) {
  const [{ menustate, isSpotAppOpenCountDown, countUserRatings, token, isSpotAppOpen, dblesenerlauben, packageOfSongsTorate, dblesencomingfrommenu}, dispatch] = useStateValue();




const anzahlSongsZufallAuswahl = [4, 4];

// const [anzahlSongsZufall, setAnzahlSongsZufall] = useState(
//   //inital value
//     0
// ); //hook example


const [anzahlSongsZufallIndex, setAnzahlSongsZufallIndex] = useState(
  //inital value
    0
); //hook example

const [AnzahlSongsZufallSet, setAnzahlSongsZufallSet] = useState(
  //inital value
    0
); //hook example
  // const [getDBInfoOnce, setGetDBInfoOnce] = useState(
  //   //inital value
  //  false
  
  // ); //hook example

  const [showSelection, setShowSelection] = useState(
    //inital value
   false
  
  ); //hook example

  //nur einmal beim laden
  useEffect(() => {

    setAnzahlSongsZufallIndex(
      Math.floor(Math.random()*anzahlSongsZufallAuswahl.length));
  
      setAnzahlSongsZufallSet(
          1);
   

    //ANMELDEN BEI DB START
          const provider = new firebase.auth.GoogleAuthProvider();
          //KEINE PARAMETER
          auth.signInAnonymously();
          //FIREBASE ANONYM LOGIN END
    //ANMELDEN BEI DB END

         







}, []);














                   //if (dblesenerlauben==1) {
                    const selectionOfTracks =  packageOfSongsTorate;
                //  }
                   



                  

                        //PROMISE START

                    const GetSelectionOFTracks = new Promise((resolve, reject) => {

                
                      if ([selectionOfTracks][0]!=undefined && AnzahlSongsZufallSet==1) {
                       // if (selectionOfTracks!=null) {
                          resolve()
                        } else {
                      
                          reject('Failed')
                        }
                      
                      })
                      
                      //alles bei then passiert nur bei sucess zeile 201
                      GetSelectionOFTracks.then(() => {
              
     
                      
                       setShowSelection(true);
                      //! RETURN BASED ON PROMISE REACHED START
                      
                      //! RETURN BASED ON PROMISE REACHED END
                      
                      //then end
                      }).catch((message) => {
                      
                        console.log('this is the catch ' + message);
                      
                      });



                        //PROMISE ENDE
















  //wechsel zu eigenem song adden (auf 5 setzen)
  // 5 von slides und nun der 6ste!
  if (countUserRatings===6) {
   
    //LADEN DER SONG HINZUFÜGEN MASKE, WENN GENUG SONGS GERATED
   //ChooseSong.js wird geladen

   dispatch({
    type: "SET_RATINGSNUREINMALCALC",
    nurEinmalRatngsCalc: 0,
      });
      
      dispatch({
        type: "SET_MENUSTATE",
        menustate: "addownsong",
      });

   if ( token && menustate==="addownsong" ) return <ChooseSong/>
  }





  var user = firebase.auth().currentUser;
  var uid;

 // SORGT FUER PFLICHT USER!!! 
// user ist von Firebase!!!  // limit-db-read // 
  if (user != null && dblesenerlauben==1 && dblesencomingfrommenu==1) {
    //HAT NICHTS MIT userid UNTEN ZU TUN (DIES IST SPOTIFY USERID)
    uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                     // this value to authenticate with your backend server, if
                     // you have one. Use User.getToken() instead.
                  
                  
                  // return ist benötigt    // dies ist spotuser id
                  return <FBGetSongsFromDB userid={userid} anzahlSongsZufall={anzahlSongsZufallAuswahl[anzahlSongsZufallIndex]}/>
  
             

                    }





                  
                  






  return (

   

    <div className="feedbackBodySpons">
       
      <Header spotify={spotify} />
      <h2>You are just one step away from submitting your song!</h2>
<h1>PLAY & RATE ALSO this sponsored Song <br/>and you can submit your own song</h1>
                                {/* bedeutet null=initial state */}
{/* <h4>temp: state isSpotAppOpen: {(!isSpotAppOpen) ? (<p>null</p>) : (isSpotAppOpen.status)}</h4>
<h4>temp: state isSpotAppOpenCountDown: {(!isSpotAppOpenCountDown) ? (<p>null</p>) : (isSpotAppOpenCountDown.status)}</h4> */}



    
    {/* // SPONSORED */}
<ShowSponsoredBoxes deliveredBoxes={4} spotify={spotify}/>



{/* <div>  

<p>UserID:{userstartseite.id}</p>

</div> */}
{/* <SongBoxSponsored /> */}
{/* Amount of Song Boxes mit weniger als 5 Ratings:
{countSongBoxes}

<br />UserID:{userstartseite.id}
countUserRatings: {countUserRatings} */}
     
  {/* <div className="testSeveralPlayers">
 { <p>Test-MSG avoid cheating: {infoSongPlaying.item.name}</p>  }
 { <p>Test-MSG avoid cheating ID: {infoSongPlaying.item.id}</p>  }
  </div> */}
<div className="songsFromSpot">

  Song Images and Infos coming from:<br />
  <img
        src={SpotLogoLogin}
        alt="Login to Spotify"
    
      />
</div>
    </div>
  );
}

export default FeedbackCarouselBodyUserFetched;
