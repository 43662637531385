import React, { useState, useEffect } from 'react';
import SpotifyWebApi from "spotify-web-api-js";  // I guess it is this: https://www.npmjs.com/package/spotify-web-api-node
import { useStateValue } from "./StateProvider";
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Player from "./Player";
//import ChooseSong from "./ChooseSong";
import { getTokenFromResponse } from "./spotify";
import "./App.css";
import Login from "./Login";
// import ChoosePlaylist from "./ChoosePlaylist";
import ChooseSong from "./AddMySong/ChooseSong";
// import EnjoyTheCarousel from "./EnjoyTheCarousel";
import FeedbackCarousel from "./FeedbackCarousel/FeedbackCarousel";
import SponsFeedbackCarousel from "./Sponsoredslide/SponsFeedbackCarousel";
// import SpotifyJam from "./SpotifyJam";
import RatingsOfMySong from "./MyRatings/RatingsOfMySong";
import CheatMSG from "./CheatMSG";
import FaqSlide from "./FaqSlide";

import MeldungSongGeAdded from "./AddMySong/MeldungSongGeAdded";
import { NavBar } from "./responsive/navbar";
import NoPlayerMSG from "./NoPlayerMSG";
import TagManager from 'react-gtm-module';
import ImprintDataPrivacyContact from "./FooterAndLinks/ImprintDataPrivacyContact";

const tagManagerArgs = {
  gtmId: 'GTM-T68K8MH'
}

TagManager.initialize(tagManagerArgs);



//WANN IST READ DB WIEDER ERLAUBT, ANZAHL SEKUNDEN?
const timeUntilNoMoreChecks=120;




//FIREBASE STOPP

const s = new SpotifyWebApi();
//, menustate
function App() {      // beide nachher erlauben
  const [{ token, menustate, notokenmenuclick}, dispatch] = useStateValue();
 // const [{ token, menustate, dblesenerlauben, dblesencomingfrommenu, ratingdblesenerlauben, notokenmenuclick}, dispatch] = useStateValue();
  const [counter, setCounter] = useState(timeUntilNoMoreChecks);


  useEffect(() => {
    // Set token
    const hash = getTokenFromResponse();
    window.location.hash = "";
    let _token = hash.access_token;

    if (_token) {
      s.setAccessToken(_token);

      dispatch({
        type: "SET_TOKEN",
        token: _token,
      });



//für avatar
      dispatch({
        type: "SET_SPOTIFY",
        spotify: s,
      });
//für avatar
      s.getMe().then((user) => {
        dispatch({
          type: "SET_USER",
          user,
        });
      });


    }
  }, [token, dispatch]);


// COUNTER FÜR READ-BESCHRÄNKUNG START

// über reducer lesen erlaubt ja / nein

  // Bei jedem counter change
useEffect(() => {

  counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

  //passiert jede sekunde bis zeit abgelaufen

  // automatisch zurück wenn User etwas abspielt!!!


}, [counter]);
//COUNTER MENUSPERRE AKTIVIEREN (1 Sekunde früher)

if (counter===1) {

  dispatch({
    type: "SET_DBLESENCOMINGMENU",
    dblesencomingfrommenu:0,
  });

  //sofort danach auf 0 setzen sonst infinite calls
  setCounter(0);
}
//COUNTER FÜR READ-BESCHRÄNKUNG ENDE
if (counter===0) {
  


  //Carousel
  dispatch({
    type: "SET_DBLESENJANEIN",
    dblesenerlauben:1,
  });

  //Rating Step 1
  dispatch({
    type: "SET_RATINGDBLESENJANEIN",
    ratingdblesenerlauben:1,
  });



  //wieder auf gesetzte Sekunden
setCounter(timeUntilNoMoreChecks);

}

  return (
    <div className="app">

      {/* // ÜBERGREIFENDE TEMP MELDUNGEN  */}
      {/* <p>Counter übergreifend {counter} über reducer lesen erlaubt ja / nein {dblesenerlauben} menulsenerlauben {dblesencomingfrommenu}</p>

     <p>Rating DB erlauben {ratingdblesenerlauben}</p> */}
 
     
      {!token && notokenmenuclick===true && menustate==="imprintdataprivacy" && <ImprintDataPrivacyContact />}
      {!token && notokenmenuclick===false && <Login />} 

    {/* //ERST NACH Login  */}
    {/*  */}
    {token && <NavBar /> }
      {token && menustate==="feedbackcarouselmenu" && <FeedbackCarousel spotify={s} />}
      
      {token && menustate==="ratingsofmysong" && <RatingsOfMySong spotify={s} />}
      {token && menustate==="addownsong" && <ChooseSong />}
      {/* {token && menustate==="choosesongmenu" && <ChooseSong  spotify={s}/>} */}
      {/* HIER GEBRAUCHT SONST GEHT ES NICHT!! */}
      {token && menustate==="cheatmessage" && <CheatMSG spotify={s} />} 
      {token && menustate==="noplayermessage" && <NoPlayerMSG spotify={s} />} 
      {token && menustate==="faqslide" && <FaqSlide spotify={s} />}
      {token && menustate==="songaddedtopool" && <MeldungSongGeAdded/>}
      {token && menustate==="imprintdataprivacy" && <ImprintDataPrivacyContact />}
      {token && menustate==="newsletter" && <FeedbackCarousel spotify={s} />}
      {token && menustate==="sponsoredslide" && <SponsFeedbackCarousel spotify={s} />}
    </div>
  );
  


}




export default App;
