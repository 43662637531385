import React from 'react';

import FooterAutoplay from "../FooterAndLinks/FooterAutoplay";

import Footer from "../FooterAndLinks/Footer";

import "../Player.css";

import FeedbackCarouselBody from "./FeedbackCarouselBody";

import { useStateValue } from "../StateProvider";


// FeedbackCarousel
// const s = new SpotifyWebApi();

function Player({ spotify }) {

  const [{ showAutoPlayFooter }, dispatch] = useStateValue();


  //für avatar und user id (nötig damit nicht eigene songs angezeigt werden)
  // dispatch({
  //   type: "SET_SPOTIFY",
  //   spotify: s,
  // });
  //für user id (nötig damit nicht eigene songs angezeigt werden)





  return (
    <div className="player">
      <div className="player__body">

        <FeedbackCarouselBody spotify={spotify}/>
      </div>


      {/* //WENN AUTOPLAY NICHT GETRIGGERED, REGULÄREN FOOTER ZEIGEN */}
      {(showAutoPlayFooter===true) ?  (  <FooterAutoplay />) : (    <Footer/>)}




  
    </div>
  );




  }




export default Player;
