//NEU ÜBER DEVICES Get a User's Available Devices

// https://developer.spotify.com/documentation/web-api/reference/#endpoint-get-information-about-the-users-current-playback

import React, { useState, useEffect } from 'react';
import './SongBoxCountDown.css';
import { useStateValue } from "../../StateProvider";
import SpotifyJam from "../../SpotifyJam";

import NoPlayerMSG from "../../NoPlayerMSG";


import { firestore, auth } from '../../firebase';

import firebase from 'firebase/app';


//import { useCollectionData } from 'react-firebase-hooks/firestore';

//LÄNGE BIS RATING MÖGLICH, evtl. monetization : nach 2 minuten gratis, nach 40 seks kostet
const timeUntilRating=37;





                  

function SongBoxCountDownSponsored({trackname}) {
    const [{ infoSongPlaying, isSpotAppOpenCountDown, checkIfSpotclosed, countUserRatings, token, menustate}, dispatch] = useStateValue();
    const [counter, setCounter] = React.useState(timeUntilRating);
    // const [counteruntensong, setCounterTwo] = React.useState(timeUntilRating);
  
  
  
    //NUR BEIM LADEN
useEffect(() => {





      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    //   if (counter === 0) {setCounter(timeUntilRating)};
    //   counteruntensong > 0 && setTimeout(() => setCounterTwo(counteruntensong - 1), 1000);
    //   if (counteruntensong === 0) {setCounterTwo(timeUntilRating)};

   // if (counter<(timeUntilRating-3)) {
// Bedingung info über song holen alle 5 holen sekunden nach start info über song holen
//SONG MUSS MIND. 5 SEKUNDEN SPIELEN DAMIT ER INFO FETCHED!!!!
if (counter==15 ) {

         //NUR ALLE SEKUNDEN
         SpotifyJam.getPlayerInfo(token).then((response) =>
         dispatch({
          type: "SET_INFOSONGPLAYING",
          infoSongPlaying: response,
        
        }),




        );  //INFO ÜBER SPIELENDEN SONG HOLEN ENDE



 }; // Bedingung info über song holen ende

//hat er spotify geschlossen während play???
if (counter==2 && isSpotAppOpenCountDown.status!=313131) {



  // alert ("Please finish listing to the current song:)"+"infoSongPlayingID = "+infoSongPlaying.item.id+" // "+" Box ID = "+songToPlay)
 
  dispatch({
    type: "SET_MENUSTATE",
    menustate: "noplayermessage",
  });

  if (token && menustate==="noplayermessage") return <NoPlayerMSG />




};

    
  }, [counter]);


//INITIAL VALUE COUNTDOWN ZEIGEN
const [ratingDone, setRatingDone] = useState(
  //inital value
    0


); //hook example
//INITIAL VALUE PLAY BLOCKER AUFHEBEN
const [playBlockerAufgehoben, setplayBlockerAufgehoben] = useState(
  //inital value
    0


); //hook example

const [gesetztesRating, setGesetztesRating] = useState(
  //inital value
    0

); //hook example


//WURDE SPOT BEENDET NACH SONG PLAY START

//Zurest current state holen und ZEIT LASSEN
//BEI 40 SEC HAT ER ES NOCH NICHT!!!
if (counter==12 && checkIfSpotclosed==0) {

  
  // delete  isSpotAppPlayerStateReceived




// console.log(infoSongPlaying);
const playbackStateFetched = new Promise((resolve, reject) => {
// console.log(spotifydata);
 // BEI 20 SEKUNDEN GELESEN
if ( infoSongPlaying.is_playing === true )

 {
      
       // Output items
     //  if (data.body && data.body.is_playing) {
 
         resolve('info spot playing received')
     } else {

       reject('info spot playing failed')

     }

  
   }) // promise end
  

                 //then gleich erfolgreich
   playbackStateFetched.then(() => {
     
  //   console.log(data.body);
     dispatch({
 
       type: "SET_ISSPOTAPPOPENCOUNTDOWN",
       isSpotAppOpenCountDown: {status: 313131},
 
     })


   }).catch(() => {

   //  console.log(data.body);
     dispatch({
 
       type: "SET_ISSPOTAPPOPENCOUNTDOWN",
       isSpotAppOpenCountDown: {status: "no-spot-app-opened"},
 
     })
   }
   
   
   
   )



   dispatch({
 
     type: "SET_CHECKIFSPOTCLOSED",
     checkIfSpotclosed: 1,

   })



   };
//WURDE SPOT BEENDET NACH SONGPLAY END





//RATING IN FORM ERMITTELN START
function onChangeValue(e) {
  const ratingscoreasnumber = parseInt(e.target.value, 10)

// BEI SPONSORED NUR RATINGS DES USERS ZÄHLEN


    dispatch({
    type: "SET_COUNTUSERRATINGS",
     countUserRatings: countUserRatings+1,
     });

     setGesetztesRating(ratingscoreasnumber);


//DB OWN RATINGS START

const trackunique = Date.now()


// CREATE DATE

const ratingDate = trackunique;
const ratingDateToString = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(ratingDate);


const provider = new firebase.auth.GoogleAuthProvider();
//KEINE PARAMETER
auth.signInAnonymously();

                     const songRatingsRef = firestore.collection("sponsoredRatings");


                               //FIREBASE ANONYM LOGIN START

    
                                               //MUSS UPDATE WERDEN
                     songRatingsRef.doc("sponsoredRatings").update({
                       
                       
                   //    ratingdates: firebase.firestore.FieldValue.arrayUnion(trackunique+"-*_1&7&1&"+Date.prototype.getUTCFullYear()+"."+Date.prototype.getMonth()+"."+Date.prototype.getUTCHours()+"."+Date.prototype.getUTCMinutes()),
                   ratingdates: firebase.firestore.FieldValue.arrayUnion(ratingDateToString),

                       tracknames: firebase.firestore.FieldValue.arrayUnion(trackunique+"-*_1&7&1&"+trackname),
                      
                       //text: formValue,
              
                       // createdAts: firebase.firestore.FieldValue.arrayUnion(firebase.firestore.FieldValue.serverTimestamp()),
                       // uid,
                      // trackid: trackid, //wird so genannt in songbox übergabe
                     //  fromspotifyuser: user.id,
                    
               ratingscores: firebase.firestore.FieldValue.arrayUnion(trackunique+"-*_1&7&1&"+ratingscoreasnumber)
                    
                     // albumid: track.albumid
                       // artist: track.artist,
                       // name: track.name
                     
                      
           
                       });






// DB OWN RATINGS STOPP









setRatingDone(1);
  




        
        
        dispatch({
          type: "SET_INFOSONGPLAYING",
          infoSongPlaying:
        
          //ID WICHTIG FUER CHEATUEBERWACHUNG
        {
        
        
          "is_playing": false, //wichtig wegen cheating
        
               "item": {
        
         
        
              "id": "ischeating",  //WICHTIG FUER CHEATUEBERWACHUNG
              "name": "check is cheating",
        
              }
        },
        
        
        }) //song cheating chekck zu ausgangslage ende
        
     

        //CHEATCHECK SONG ZU AUSGANGSLAGE ENDE



   

}; //RATING ON CLICK END
//RATING IN FORM ERMITTELN ENDE


//playblocker aufheben start COUNTER 0 START

if (counter===0 && playBlockerAufgehoben!=1 && ratingDone===1 ) {
dispatch({
  type: "SET_PLAYBLOCKER",
  playBlocker: 0,

});

setplayBlockerAufgehoben(1);






};//playblocker aufheben ende COUNTER 0 ENDE






    return (
      <div>
       {(counter >= 1)? 
        (

          <div>
           <p>Rating box will appear after:</p>
          <h3>{counter}</h3>
          <p>Seconds</p>

          </div>

          ) : (<div>&nbsp;</div>)}

      
     

        
        {/* IF IN RETURN : RATINGBOX ZEIGEN BEI 0*/}
        {(counter === 0 && ratingDone!=1)? 
        (//RATING CONDITION START
            <div className="ratingBox" onChange={onChangeValue}>
                PLEASE RATE:<br /><br />
            <input type="radio" value="1" name="userrating" className="spRating"/> 😉 Not for me<br /><br />
            <input type="radio" value="2" name="userrating" className="spRating"/> 🟢 Mediocore<br /><br />
            <input type="radio" value="3" name="userrating" className="spRating"/> 🎉 Great Song<br />
            </div>
        //RATING CONDITION END    
        ) : (
       <p></p> //<p></p>  muss bleiben!!
      )}       
{(ratingDone===1)? 
(<div className="songIsRatedBox"><p>Song successfully rated </p>
<p>with:&nbsp;
  {(gesetztesRating==1)? ("😉 Not for me"):("")}
  {(gesetztesRating==2)? ("🟢 Mediocore"):("")}
  {(gesetztesRating==3)? ("🎉 Great Song"):("")}
</p>

</div>
  ) : (
    <p></p>  //<p></p>  muss bleiben!!
  )}


        </div>
    )
}

export default SongBoxCountDownSponsored
