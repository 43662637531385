import React, { useState } from 'react';
import { useStateValue } from "../StateProvider";
import { firestore } from '../firebase';

import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';


                    //userid gebraucht!! nur TEMPORÄR nicht gezeigt
function FBGetSongsFromDB({userid, anzahlSongsZufall}) {
    // packageOfSongsTorate IST BENÖTIGT
const [{packageOfSongsTorate, dbTrackSelection, slideNrToShowToUser}, dispatch] = useStateValue();









  const [showSelection, setShowSelection] = useState(

   false

  ); 





const tracksToRateRef = firestore.collection(dbTrackSelection);


  // !* !* NICHT LOESCHEN!* !* LIVE: 
const query = "";
    

  //FOR TESTING KEIN WHERE // 15 holen für 3 slides
  //  const query = tracksToRateRef.limit(15);




    const [selectionOfTracks] = useCollectionDataOnce(query, {idField: 'id'});
    




                 //PROMISE START
                 const GetSelectionOFTracks = new Promise((resolve, reject) => {


                    if ([selectionOfTracks][0]!=undefined) {
                      resolve()
                    } else {
                      reject('Failed')
                    }
                  })
                  
                  //alles bei then passiert nur bei sucess zeile 201
                  GetSelectionOFTracks.then(() => {


                  
                   setShowSelection(true);


                  }).catch((message) => {
                  
                    console.log('this is the catch ' + message);
                  
                  });





// nachpromise

// SLIDE 1 - 4 SONGS
if (showSelection===true && slideNrToShowToUser===1 && anzahlSongsZufall==4) {
 

              dispatch({
               type: "SET_PACKAGEOFSONGSTORATE",
                                                          //start, end does not include, the given end argument.
               packageOfSongsTorate: selectionOfTracks.slice(0,4),
            });

    dispatch({
        type: "SET_DBLESENJANEIN",
        dblesenerlauben:0,
      })


}


// SLIDE 1 - 5 SONGS

if (showSelection===true && slideNrToShowToUser===1 && anzahlSongsZufall==5) {
 

  dispatch({
   type: "SET_PACKAGEOFSONGSTORATE",
                                              //start, end -> end not inluded
   packageOfSongsTorate: selectionOfTracks.slice(0,5),
});

dispatch({
type: "SET_DBLESENJANEIN",
dblesenerlauben:0,
})


}

// SLIDE 2 - 4 SONGS

if (showSelection===true && slideNrToShowToUser===2 && anzahlSongsZufall==4) {
 

  dispatch({
   type: "SET_PACKAGEOFSONGSTORATE",
                                  //start, end -> end not inluded
   packageOfSongsTorate: selectionOfTracks.slice(5,9),
});

dispatch({
type: "SET_DBLESENJANEIN",
dblesenerlauben:0,
})


}



// SLIDE 2 - 5 SONGS

if (showSelection===true && slideNrToShowToUser===2 && anzahlSongsZufall==5) {
 

  dispatch({
   type: "SET_PACKAGEOFSONGSTORATE",       //start, end -> end not inluded
   packageOfSongsTorate: selectionOfTracks.slice(5,10),
});

dispatch({
type: "SET_DBLESENJANEIN",
dblesenerlauben:0,
})


}


// SLIDE 3 - 4 SONGS

if (showSelection===true && slideNrToShowToUser===3 && anzahlSongsZufall==4) {

  dispatch({
   type: "SET_PACKAGEOFSONGSTORATE",
                                  //start, end -> end not inluded
   packageOfSongsTorate: selectionOfTracks.slice(10,14),
});

dispatch({
type: "SET_DBLESENJANEIN",
dblesenerlauben:0,
})

}

// SLIDE 3 - 5 SONGS

if (showSelection===true && slideNrToShowToUser===3 && anzahlSongsZufall==4) {

  dispatch({
   type: "SET_PACKAGEOFSONGSTORATE",
                                  //start, end -> end not inluded
   packageOfSongsTorate: selectionOfTracks.slice(10,15),
});

dispatch({
type: "SET_DBLESENJANEIN",
dblesenerlauben:0,
})

}

// if (dbRequestDone==0) {


//     export const getMetaOutsider = () => {
//         return (dispatch) => {
//           Axios.get("meta/outsiders")
//           .then(res => {
//             dispatch({ type: GET_OUTSIDER_META_INFORMATION, payload: res.data });
//           })
//           .catch(err =>
//             dispatch({ type: POPULATE_ERRORS, payload: err.response.data })
//           );
//         }
//       };



// dispatch({
// type: "SET_SELECTIONOFTRACKS",
// selectionOfTracksFetched: [selectionOfTracks][0],
// });

// setdbRequestDone(1);

// }

    return (
        <div>
            
        </div>
    )
}

export default FBGetSongsFromDB
