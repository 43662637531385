import React from "react";
import Footer from "./FooterAndLinks/Footer";
import "./Player.css";
import FaqSlideBody from "./FaqSlideBody";

// import Footer from "./Footer";

function Player({ spotify}) {




  return (
    <div className="player">
      <div className="player__body">

        <FaqSlideBody spotify={spotify}/>
      </div>
      <Footer />
    </div>
  );



}
export default Player;
