import React, { useState, useEffect } from 'react';
import "./RatingBox.css";

import DisplayRatingsOfSongBox from "./DisplayRatingsOfSongBox.js"
import SongImage from "../FeedbackCarousel/SongBoxes/SongImage.js";
import SpotifyJam from "../SpotifyJam";
import { useStateValue } from "../StateProvider";

//spotify gebraucht um Cover anzuzeigen bei Ratings

function RatingBox( {trackForBox} ) {
    const [{token}] = useStateValue();



  

// GEBRAUCHT UM COVER ANZUZUEIGEN BEI RATINGS

  const [trackInfo, setTrackInfo] = useState(
    //inital value

{

  album:  {
    "name" : "Can't Stop Raving",
  //  "id": "0tGPJ0bkWOUmH7MEOR77qc"
  "id" : "67EEM8KMmXq8qxQXdRpmXe"
   //  "id" : "1hMjJNZT1jhuWNotbgTRsB"
  },
  artists: [

    {name: "Dune"},
    {artistid: "4u78tFtzvcrrs8kzkXsW4r"}

  ],

  
id: "5Yf5TUA0ROqVoopGlSqzYM", // puremia
 // id: "4Q7fEjeCZgU8oxjbLWrm7L",
  name: "Can‘t Stop Raving (Video Mix)",
  popularity: 54,
  tracknumber: 1,
  uri: "spotify:track:4Q7fEjeCZgU8oxjbLWrm7L"
  }


); //hook example




useEffect(() => {


    // GET TRACK INFO BASED ON TRACK ID
    SpotifyJam.getTrackInfo(trackForBox.trackIdOfSong, token).then((response) =>


    setTrackInfo( response )
    )


    
        
  }, [] );








 
const amountOfEntitledRatings = parseInt(trackForBox.entitledRatings, 10); //10=Dezimalsystem



  return (
     
  <div className="ratingBoxMyRatings">

    <div className="titleRowRatings">
      
      <div className="albumImageRatings">
      {/* //WENN UNGÜLTIGE SONGID KOMMT COVER PUREMIAS FIRST SONG ALBUM ID=ERSTE KLAMMER */}
      {(trackInfo.hasOwnProperty("error")===true) ?  (<h4><SongImage AlbumId="5Zn8yBhD95lylOMCIc5LwE"/></h4>) : (<SongImage AlbumId={trackInfo.album.id}/>)}
      </div>

      <div className="songRow__info">
      {/* //WENN UNGÜLTIGE SONGID KOMMT kein Song beim Countdown, trotzdem Rating     KLAMMER IST SMILEY*/}
      {(trackInfo.hasOwnProperty("error")===true) ?  (<p>Very quiet Artist - very Quiet song;)</p>) : (<h3>Your song "{trackForBox.songName}" <br />is entitled for {amountOfEntitledRatings} ratings.</h3>)}
      </div>

    </div>
      
          {/* RATING LINE  styling see css of DisplayRatingsOfSongBox.js */}
          <DisplayRatingsOfSongBox ratingInfo={trackForBox}/>
    </div>


  ) 




}  
export default RatingBox;


