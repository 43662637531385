//  !! MUSS AUCH STATUS PLAYER AUSLESEN UND IN ÜBERGREIFENDE VARIABEL SCHREIBEN !!!


import React, { useState, useEffect } from 'react';
import "./FeedbackCarousel/FeedbackBody.css";
import FeedbackCarousel from "./FeedbackCarousel/FeedbackCarousel";
import Header from "./Header";
import { useStateValue } from "./StateProvider";
// import TagManager from 'react-gtm-module';
// import Timer from "./Timer";
// import SongRow from "./SongRow";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { testTrackThree } from "./testtrack";


// import { AlternateEmail } from "@material-ui/icons";
// import TrackResult from "./TrackResult";
//import SearchResults from "./jammming-src/Components/SearchResults/SearchResults";
//import TrackList from "./jammming-src/Components/TrackList/TrackList";
//import Track from "./jammming-src/Components/Track/Track";

// Tag Manager Start


// Tag Manager End



// player ready ermittlung jede.1 sekunde für gesetzte Sekunden:
const timeUntilNoMoreChecks=120;

function NoPlayerMSGBody() {
const [{ token, menustate, spotify }, dispatch] = useStateValue();
const [counter, setCounter] = useState(timeUntilNoMoreChecks);

// Bei jedem counter change
useEffect(() => {

    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

    //passiert jede sekunde bis zeit abgelaufen

    // automatisch zurück wenn User etwas abspielt!!!

    spotify.getMyCurrentPlayingTrack().then((getInfo) => {


        if (getInfo) {
           // console.log("User is currently playing something!");
        
           dispatch({
            type: "SET_DBLESENCOMINGMENU",
            dblesencomingfrommenu:1,
          });
        
           backToCarousel();
        } 
}
);
}, [counter]);




function backToCarousel() {

    //bracht es nicht oben, da nur variabel gesetzt wird
    dispatch({

        type: "SET_ISSPOTAPPOPEN",
        isSpotAppOpen: {status: "songisplaying"},

      })



          //song cheating chekck zu ausgangslage start
        
        
          dispatch({
            type: "SET_INFOSONGPLAYING",
            infoSongPlaying:
          
            //ID WICHTIG FUER CHEATUEBERWACHUNG
          {
          
          
            "is_playing": false, //wichtig wegen cheating
          
                 "item": {
          
           
          
                "id": "ischeating",  //WICHTIG FUER CHEATUEBERWACHUNG
                "name": "check is cheating",
          
                }
          },
          
          
          }) //song cheating chekck zu ausgangslage ende

  
          //CHEATCHECK SONG ZU AUSGANGSLAGE ENDE



          
          dispatch({
            type: "SET_PLAYBLOCKER",
            playBlocker: null,

          })

          dispatch({
            type: "SET_DBLESENCOMINGMENU",
            dblesencomingfrommenu:1,
          });
 


   
    //LADEN DER SONG HINZUFÜGEN MASKE, WENN GENUG SONGS GERATED
   //ChooseSong.js wird geladen
      dispatch({
        type: "SET_MENUSTATE",
        menustate: "feedbackcarouselmenu",
      });

   
    if (token && menustate==="feedbackcarouselmenu") return <FeedbackCarousel spotify={spotify}/>



}

  return (


    <div className="feedbackBody">
       
      <Header/>
      {/* <Header spotify={spotify} /> */}

      <div className="messageText">
        <h1>Your Spotify App needs to get connected!</h1>
        <h2>
        <p>&nbsp;</p>
            <p>
            Before you can use the Song Carousel please:<br /> - Have your Spotify App opened <br /> - Play a random song
            in your Spotify App
            
            <br /> - Please make sure you are not in private mode while playing Songs and that you have a Premium Spotify Account
            
            </p> </h2>
            <br/>
            <p>How to fix this - help Video:</p>
            <p>

            <iframe width="560" height="315" src="https://www.youtube.com/embed/xMTwlnm1VFg?start=62" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        
            </p>


           
            <br/>
       
        </div>
        <div className="takeMeBack" onClick={backToCarousel} >
        <h2><a href="#" className="BackToCarouselAfterNoSpot">OK I'm ready now - <br />Take me to the Song Carousel</a></h2></div>
   

       
            

     


    </div>
  );
}

export default NoPlayerMSGBody;
