import React from "react";
import "./Header.css";
import { useStateValue } from "./StateProvider";
import { Avatar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect } from "react";

function Header({ spotify }) {
  const [{ user }, dispatch] = useStateValue();



  // const playSong = (id) => {
  //   spotify
  //     .play({
  //       uris: [`spotify:track:${id}`],
  //     })
  //     .then((res) => {
  //       spotify.getMyCurrentPlayingTrack().then((r) => {
  //         dispatch({
  //           type: "SET_ITEM",
  //           item: r.item,
  //         });
  //         dispatch({
  //           type: "SET_PLAYING",
  //           playing: true,
  //         });
  //       });
  //     });
  // };



//features https://developer.aliyun.com/mirror/npm/package/spotify-web-api-node














// console.log(user);

  return (


    

    <div className="header">
      <div className="header__left">

          
        
 
  


  
      </div>
      <div className="header__right">
        {/* <p><h4>{user?.display_name}</h4></p> */}
        
{/* <p>URL: {user.images[0].url}</p> */}
        {(user?.images.length===0) ?  (<h4>{user?.display_name}</h4>) : (<Avatar alt={user?.display_name} src={user?.images[0].url} />)}
      </div>
    </div>
  );
}

export default Header;
