import React from "react";
import "./Footer.css";

import ImprintDataPrivacyContact  from "./ImprintDataPrivacyContact";

import { useStateValue } from "../StateProvider";



function FooterNoLogin() {
  const [{ menustate }, dispatch] = useStateValue();



    function footerClick() {

        dispatch({
            type: "SET_NOTOKENMENUCLICK",
            notokenmenuclick: true,
          });



      dispatch({
        type: "SET_MENUSTATE",
        menustate: "imprintdataprivacy",
      });

 if (   menustate==="imprintdataprivacy") return <ImprintDataPrivacyContact />
    }


 



  

  return (
    <div className="footer" onClick={footerClick}>

<a href="#">Imprint</a> | 
<a href="#">Privacy Policy</a> | 
<a href="#"> Contact</a> 
    </div>
  );





}





export default FooterNoLogin;
