import React from "react";
import styled from "styled-components";
import { Accessibility } from "./accessibility";
import { MenuToggle } from "./menuToggle";
import { SidebarDataForMenu } from "./SidebarDataForMenu";
import Link from "./Link";
import { useStateValue } from "../../StateProvider";

const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 100000;
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
  background-color: rgb(240, 240, 240);
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 100000;

`;

const LinkItem = styled.li`
  width: 100%;

  padding: 0 1.1em;
  padding-top: 12px;
  color: #222;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  z-index: 100000;

  margin-bottom: 10px;
`;

// const Link = styled.a`
//   text-decoration: none;
//   color: inherit;
//   font-size: inherit;
// `;

const Marginer = styled.div`
  height: 2em;
`;

export function MobileNavLinks(props) {

  const [{isOpen}, dispatch] = useStateValue();
  

  return (
    <NavLinksContainer>
      <MenuToggle isOpen={isOpen} toggle={() => 
        
        
        {

          dispatch({
            type: "SET_STATEMOBILEMENU",
            isOpen: !isOpen,
          });

        }
 
        
        } />

      {/* // WIRD GERENDET WHEN isOpen */}
      {isOpen && (
        <LinksWrapper>



          {SidebarDataForMenu.map((item, index) => {


          return <LinkItem><Link option={item.title} _menustate={item.menustatedeliver} > </Link></LinkItem>
 

          })}

          {/* <LinkItem>
            <Link href="#">About us</Link>
          </LinkItem>
          <LinkItem>
            <Link href="#">How it works</Link>
          </LinkItem>
          <LinkItem>
            <Link href="#">Explore</Link>
          </LinkItem>
          <LinkItem>
            <Link href="#">Impact</Link>
          </LinkItem> */}
          <Marginer />
          <Accessibility />
        </LinksWrapper>
      )}
    </NavLinksContainer>
  );
}
