import React, { useState } from "react";
import "./Link.css";
import { useStateValue } from "../../StateProvider";
import { MenuToggle } from "./menuToggle";

function Link({option, _menustate}) {
    // es braucht "{}" bei {}, dispatch


    const [{isOpen}, dispatch] = useStateValue();

    function SetMenuState() {
    

    //ZUERST

  dispatch({
    type: "SET_DBLESENCOMINGMENU",
    dblesencomingfrommenu:1,
  });

        //wenn zurück auf carousel nach ratings
        dispatch({
          type: "SET_PLAYBLOCKER",
          playBlocker: null,

        })
     
        dispatch({
            type: "SET_STATEMOBILEMENU",
            isOpen: false,
          });


          dispatch({
            type: "SET_MENUSTATE",
            menustate: _menustate,
          });

          if (_menustate==="newsletter"){

            window.open("https://catchyrecords.us1.list-manage.com/subscribe?u=b4ff59308b463102f849ee4a7&id=599c1e5140", "_blank")
          }
          
          if (_menustate==="backtohomepage"){

            window.open("https://punk-tunes.com/", "_blank")
          }

      };
  

    return (
        <div>
                <a href="#" onClick={SetMenuState}>  <h4>{option}</h4></a>
        </div>
    )
}

export default Link
