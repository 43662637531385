import firebase from "firebase/app";

// import firebase from 'firebase/compat/app';
// import * as firebase from 'firebase';


import 'firebase/firestore';


import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
//import { collection, getDocs } from firebase;

const firebaseApp = firebase.initializeApp({

  apiKey: "AIzaSyAxXVyeVFlxumGcAe8v9bDyy977ytFJTIg",
  authDomain: "spot-feedback-carousel.firebaseapp.com",
  projectId: "spot-feedback-carousel",
  storageBucket: "spot-feedback-carousel.appspot.com",
  messagingSenderId: "559838262486",
  appId: "1:559838262486:web:4521526930b2aa99b3c577",
  measurementId: "G-3WYBEEWDP7"


  });
 




  //könnte auch db genannt werden
  const firestore = firebaseApp.firestore();
  //Authentication
  const auth = firebase.auth();
  
  //upload pictures!!!ohne amazon web services etc
  // const storage = firebase.storage();

  export { firestore, auth  };