import React from "react";
import "./Imprint.css";

import { useStateValue } from "../StateProvider";

// import Timer from "./Timer";
// import SongRow from "./SongRow";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { testTrackThree } from "./testtrack";


// import { AlternateEmail } from "@material-ui/icons";
// import TrackResult from "./TrackResult";
//import SearchResults from "./jammming-src/Components/SearchResults/SearchResults";
//import TrackList from "./jammming-src/Components/TrackList/TrackList";
//import Track from "./jammming-src/Components/Track/Track";

function ImprintDataPrivacyContactBody ({ spotify }) {
  const [{ token, menustate, notokenmenuclick}, dispatch] = useStateValue();


  function backToLogin() {

    dispatch({
      type: "SET_NOTOKENMENUCLICK",
      notokenmenuclick: false,
    });



// dispatch({
//   type: "SET_MENUSTATE",
//   menustate: "imprintdataprivacy",
// });

// menustate==="imprintdataprivacy" && <ImprintDataPrivacyContact />
}


  return (


    <div className="body">
       
  
{/* 
      <div className="song__thatWillBeAdded"> */}
        <h1>Imprint & Contact</h1>
        
        

        <p>&nbsp;</p>

            
        <p>Song Carousel is brought to you by catchyrecords.com </p>
        <p>Inquiries: catchyrecords@gmail.com </p>
        <p>&nbsp;</p>
        <h1>Privacy Policy</h1>
        
        

        <p>&nbsp;</p>

            
        <p>Song Carousel is not connected to Spotify in any way. We use the Spotify API in order to provide this Service
            to our Users. The images and other song and artist infos come from Spotify.  </p>

            <p>We use cookies in order to collect statistics that allow us to improve this service.
                We only collect the data that is necessary to run this Service. <br />Songcarousel.com never sees your Spotify Password. <br />
                The Login is done via Spotify  OAuth 2.0.<br />
                No data is passed on to 3rd parties (eg. Spotify).<br /></p><br />
                <p>We send you mails about your ratings or other information about songcarousel.com (to the email of your spotify account.)</p>

                <p>No liability is taken for errors that this platform might have (eg. bugs etc.)</p>
   
   
                <p>&nbsp;</p>

<p>&nbsp;</p>


                {!token && notokenmenuclick===true && menustate==="imprintdataprivacy" && 
          
                
                <div className="takeMeBack" onClick={backToLogin} >
                <h2><a href="#">Take me Back to the Login Screen</a></h2>
                </div>}

 
                
       
   


    </div>
  );
}

export default ImprintDataPrivacyContactBody;
