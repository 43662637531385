import React, { useState } from 'react';
import "./ChooseSongBody.css";

import { useStateValue } from "../StateProvider";
// import Timer from "./Timer";
// import SongRow from "./SongRow";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { testTrackThree } from "./testtrack";
import  SearchFormNew from "./SearchBar/SearchFormNew";
import TrackListResult from "./TrackListResults";
import MeldungSongGeadded from './MeldungSongGeAdded';

// import { AlternateEmail } from "@material-ui/icons";
// import TrackResult from "./TrackResult";
//import SearchResults from "./jammming-src/Components/SearchResults/SearchResults";
//import TrackList from "./jammming-src/Components/TrackList/TrackList";
//import Track from "./jammming-src/Components/Track/Track";

function ChooseSongBody() {
  const [{ suchbegriff, searchResults, user, songToAddChosen}, dispatch] = useStateValue();



  const [showSearch, setShowSearch] = useState(
    //inital value
   false
  
  ); //hook example

  const [showSuccessMsg, setShowSuccessMsg] = useState(
    //inital value
   0
  
  ); //hook example
//promise USER nicht null  -> erst dann search form laden
const SpotUserId = new Promise((resolve, reject) => {

  if (user!=null) {

    resolve()
  } else {

    reject('Spot User.id not received')
  }

}) //end Promise first part


SpotUserId.then(() => {


  //  console.log([songsOfUser][0][0].id);
//  console.log("ist es array:" + Array.isArray([songsOfUser]));

setShowSearch(true);
//! RETURN BASED ON PROMISE REACHED START

//! RETURN BASED ON PROMISE REACHED END

//then end
}).catch((message) => {

  console.log('this is failed msg if no spot.user.id ' + message);

});


const didSongToAddChosenChange = new Promise((resolve, reject) => {

  if (songToAddChosen==1) {

    resolve()
  } else {
    reject('song to add nicht gewaehlt')

  }

});
didSongToAddChosenChange.then(() => {

  setShowSuccessMsg(1);


}).catch((message) => {


console.log('this is failed msg  ' + message);

});



  return (


    <div className="chooseSongBody">
       
      {/* //nur wenn kein song chosen */}
      {showSuccessMsg==0 && 
<div className="introText">
        <h2>Great jobs you've rated 5 songs ✅</h2>
        <h1>Now choose the Song you want to submit:</h1>
        </div>}
        {(showSearch===true)? 

(<div>



    
{showSuccessMsg==0 &&  <SearchFormNew suchbegriff={suchbegriff} searchResults={searchResults}/>}



  

{showSuccessMsg==0 && <TrackListResult tracks={searchResults} />}



</div>) : (<div><p>Waiting for Spotify Information.</p></div>)}

   
{showSuccessMsg==1 && <MeldungSongGeadded />}
            

     


    </div>
  );
}

export default ChooseSongBody;
