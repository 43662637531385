

export const initialState = {

  // * WERDEN NICHT UMGESTELLT * BRAUCHT KEIN state, action SET_ etc , weil wird nie geändert - START
  dbTrackSelection: "selectionOfTracksTemp", // LIVE: selectionOfTracksTemp // TEST: TestSelectionOfTracksTemp
  dbUserRatings: "userRatings", // LIVE: userRatings // TEST: TestUserRatings
  // * WERDEN NICHT UMGESTELLT * BRAUCHT KEIN state, action SET_ etc , weil wird nie geändert - END

  showAutoPlayFooter: false,
  notokenmenuclick: false,

  user: null,




  slideNrToShowToUser: 1,

  userstartseite: {"id" : "nochnicht"},
  spotify: null,

  playing: false,
  getUserInfoOnce: false,

  //check if spot closed once bei play resetten
  checkIfSpotclosed: 0,
  showButtonBackToCarousel: 0,

  packageOfSongsTorate: null,

  packageOfEntitledRatings: null,

  //Carousel DB erlauben
  dblesenerlauben: 1,

  songToAddChosen: 0,

  trackIdSongToAdd: null,
  fromSpotifyUserSongToAdd: null,

  fromSpotifyUserSongToAddEmail: null,


  //Ratings DB erlauben Step 1
  ratingdblesenerlauben: 1,



  // NUR TIMER SETZT ES ZURÜCK
  dblesencomingfrommenu: 1,
  // FUER CAROUSEL
  selectionOfTracksFetched: null,

// FUER RATINGS STEP ONE
selectionOfRatingTracksFetched: null,




// FUER RATINGS STEP ONE
ratingsOfSongFetched: null,

// FUER RATINGS STEP ONE
datesOfRatingsFetched: null,

  //MOBILE MENU
  isOpen: false,

  // getDBInfoOnce: false,

  //spotfiy app open? // nicht auf null setzen, da if dann nicht funktioniert in songbox
  isSpotAppOpen: {status : "info-not-recevied"},
  
  isSpotAppOpenCountDown: {status : "info-not-recevied"},


  //für currently playing, startdaten
  item: 
  {
    "name" : "Can't Stop Raving-Dummy",
     "id" : "1hMjJNZT1jhuWNotbgTRsBdummy"
  },
  

  // für Device Info
  infoSongPlaying:
      //inital value
    //   {
    //     "devices": [
    //         {
    //             "id": "5fbb3ba6aa454b5534c4ba43a8c7e8e45a63ad0e",
    //             "is_active": false,
    //             "is_private_session": true,
    //             "is_restricted": false,
    //             "name": "My fridge",
    //             "type": "Computer",
    //             "volume_percent": 100
    //         }
    //     ]
    // },



// json response

//ID WICHTIG FUER CHEATUEBERWACHUNG
{
  "context": {
      "external_urls": {
          "spotify": "http://open.spotify.com/user/spotify/playlist/49znshcYJROspEqBoHg3Sv"
      },
      "href": "https://api.spotify.com/v1/users/spotify/playlists/49znshcYJROspEqBoHg3Sv",
      "type": "playlist",
      "uri": "spotify:user:spotify:playlist:49znshcYJROspEqBoHg3Sv"
  },
  "timestamp": 1490252122574,
  "progress_ms": 44272,
  "is_playing": false, //wichtig wegen cheating
  "currently_playing_type": "track",
  "item": {
      "album": {
          "album_type": "album",
          "external_urls": {
              "spotify": "https://open.spotify.com/album/6TJmQnO44YE5BtTxH8pop1"
          },
          "href": "https://api.spotify.com/v1/albums/6TJmQnO44YE5BtTxH8pop1",
          "id": "idalbumischeating",  
          "images": [
              {
                  "height": 640,
                  "url": "https://i.scdn.co/image/8e13218039f81b000553e25522a7f0d7a0600f2e",
                  "width": 629
              },
              {
                  "height": 300,
                  "url": "https://i.scdn.co/image/8c1e066b5d1045038437d92815d49987f519e44f",
                  "width": 295
              },
              {
                  "height": 64,
                  "url": "https://i.scdn.co/image/d49268a8fc0768084f4750cf1647709e89a27172",
                  "width": 63
              }
          ],
          "name": "Hot Fuss",
          "type": "album",
          "uri": "spotify:album:6TJmQnO44YE5BtTxH8pop1"
      },
      "artists": [
          {
              "external_urls": {
                  "spotify": "https://open.spotify.com/artist/0C0XlULifJtAgn6ZNCW2eu"
              },
              "href": "https://api.spotify.com/v1/artists/0C0XlULifJtAgn6ZNCW2eu",
              "id": "0C0XlULifJtAgn6ZNCW2eu",
              "name": "The Killers",
              "type": "artist",
              "uri": "spotify:artist:0C0XlULifJtAgn6ZNCW2eu"
          }
      ],
      "available_markets": [
          "AD",
          "AR",
          "TW",
          "UY"
      ],
      "disc_number": 1,
      "duration_ms": 222075,
      "explicit": false,
      "external_ids": {
          "isrc": "USIR20400274"
      },
      "external_urls": {
          "spotify": "https://open.spotify.com/track/0eGsygTp906u18L0Oimnem"
      },
      "href": "https://api.spotify.com/v1/tracks/0eGsygTp906u18L0Oimnem",
   "id": "ischeating",  //WICHTIG FUER CHEATUEBERWACHUNG
      "name": "Mr. Brightside",
      "popularity": 0,
      "preview_url": "http://d318706lgtcm8e.cloudfront.net/mp3-preview/f454c8224828e21fa146af84916fd22cb89cedc6",
      "track_number": 2,
      "type": "track",
      "uri": "spotify:track:0eGsygTp906u18L0Oimnem"
  }
},

  
  suchbegriff: null,
  suchbegriffkuenstler: null,
  searchResults: [],
  // searchResultsPlaylist: [],
  songIwantToHaveAdded: [],
  // songIwantToHaveAdded2: [],
  // songIwantToHaveAdded3: [],
  // songIwantToHaveAdded4:  [],
  countUserRatings: null,


//FIRESTORE
auth: null,
firestore: null,






  artistIdForImage: null,
  // myPlaylistToAddSong: [],
  // myPlaylistTracks: [],
  // myPlaylistIdForTracks: null,
  songToPlay: "4Fj6KslSYYQ1kAbafiKoz0",

  songGesetzt: "nein",
  timePerSong: 50,

  //artist image im timer
  artistImageUrlTimer: [],



    NameOfAddesSong: "No Song",
     



  //album image im timer

  albumImageUrlTimer: [],


  //für variabel im timer
  artistImageToShow: [],
  albumImageToShow: [],
  artistNameToDisplay: null,
  songNameToDisplay: null,
  // albumNameForYoutubeSlide: [],
  playBlocker: null,

//  tracks: [],

  
  //delete later
 // token: "BQBuXObAapahkUrFhpQEPxYQLS-bCF9RnfW8xi3toOQKkWl_f76JVQDPVd1wd84mqxj7eQdlS4S05_Xvkcc35-4xlPXns4iLNm4Xg8_mAwHMum9mmNB9rhPdi1xS2x9BskWc5w-ApOv7SgbHS8oVsdrLM-_c7Rj5",
  //token: "BQBQCHmCQvJxzYjlF-ec7TgABbzH2hObOc1qq81CnJTx9KOnZbTXMUQ3SaLtzHFZhm4vp3YH3LUw0alVyFEYdL591UwVlIMk_YhYQQ7m4Vvb8I7LP-URJ6pmONAXRygn7RbW35dgrjzrdOnVv5TVt0k6GImXt0dj",
  //token: "BQB-NaN9y-cjhZJp1e3Daul_QQFqYEmRp9fRbgEzCIHET4W3zlM_jZF4ak11lpDhjuxHBQQ_EbnpZmhJOVxnJelJo7wsKggTSu1RIQ7407juExCq1-wMtgj7KdMJuMNFwo3MCgGEuFZ-kb_b_G0SVTmbdiseIuLf",
  

  //startseite
  menustate: "feedbackcarouselmenu",
  //menustate: "choosesongmenu",
 //


};

const reducer = (state, action) => {
//  console.log(action);
  switch (action.type) {


    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };


      case "SET_SLIDENRTOSHOWTOUSER":
        return {
          ...state,
          slideNrToShowToUser: action.slideNrToShowToUser,
        };
  
  
        

     


      // case "SET_GETDBINFOONCE":
      //   return {
      //     ...state,
      //     getDBInfoOnce: action.getDBInfoOnce,
      //   };

      case "SET_CHECKIFSPOTCLOSED":
        return {
          ...state,
          checkIfSpotclosed: action.checkIfSpotclosed,
        };

        case "SET_DATESOFRATINGSFETCHED":
          return {
            ...state,
            datesOfRatingsFetched: action.datesOfRatingsFetched,
          };
  
          case "SET_NOTOKENMENUCLICK":
            return {
              ...state,
              notokenmenuclick: action.notokenmenuclick,
            };

          
        
        
      
        case "SET_GETUSERINFOONCE":
          return {
            ...state,
            getUserInfoOnce: action.getUserInfoOnce,
          };
          case "SET_DBLESENCOMINGMENU":
            return {
              ...state,
              dblesencomingfrommenu: action.dblesencomingfrommenu,
            };

            case "SET_SELECTIONOFRATINGTRACKS":
              return {
                ...state,
                selectionOfRatingTracksFetched: action.selectionOfRatingTracksFetched,
              };
       
              case "SET_SHOWBUTTONBACKTOCAROUSEL":
                return {
                  ...state,
                  showButtonBackToCarousel: action.showButtonBackToCarousel,
                };
              

      case "SET_SELECTIONOFTRACKS":
        return {
          ...state,
          selectionOfTracksFetched: action.selectionOfTracksFetched,
        };


        
   
        case "SET_RATINGSOFTRACKS":
          return {
            ...state,
            ratingsOfSongFetched: action.ratingsOfSongFetched,
          };


          case "SET_TRACKIDSONGTOADD":

            return {
              ...state,
              trackIdSongToAdd: action.trackIdSongToAdd,
            };

          case "SET_FROMSPOTIFYUSERSONGTOADD":
            return {
              ...state,
              fromSpotifyUserSongToAdd: action.fromSpotifyUserSongToAdd,
            };

            
            case "SET_FROMSPOTIFYUSERSONGTOADDEMAIL":
              return  {
                ...state,
                fromSpotifyUserSongToAddEmail: action.fromSpotifyUserSongToAddEmail,
              };




    case "SET_PLAYSTARTED":
      return {
      ...state,
        playStarted: action.playStarted,
      };

      case "SET_USERSTARTSEITE":
        return {
          ...state,
          userstartseite: action.userstartseite,
        };
  //FIRESTORE START
        case "SET_AUTHFIRESTORE":
          return {
            ...state,
            auth: action.auth,
          };
          case "SET_FIRESTOREFIRESTORE":
            return {
              ...state,
              firestore: action.firestore,
            };
  //FIRESTORE ENDE

//SEHEN WAS PLAYED...
  case "SET_ITEM":
    return {
      ...state,
      item: action.item,
    };


    case "SET_ISSPOTAPPOPEN":
      return {
        ...state,
        isSpotAppOpen: action.isSpotAppOpen,
      };

      case "SET_ISSPOTAPPOPENCOUNTDOWN":
        return {
          ...state,
          isSpotAppOpenCountDown: action.isSpotAppOpenCountDown,
        };

        case "SET_SONGTOADDCHOSEN":
          return {
            ...state,
            songToAddChosen: action.songToAddChosen,
          };
  
    
case "SET_STATEMOBILEMENU":
  return {

    ...state,
    isOpen: action.isOpen,
  }






    case "SET_INFOSONGPLAYING":
      return {
        ...state,

        infoSongPlaying: action.infoSongPlaying,

      };

      case "SET_RATINGDBLESENJANEIN":
        return {
          ...state,
          ratingdblesenerlauben: action.ratingdblesenerlauben,
        };



      case "SET_DBLESENJANEIN":
        return {
          ...state,
          dblesenerlauben: action.dblesenerlauben,
        };

      case "SET_NAMEOFADDEDSONG":
        return {
          ...state,
          NameOfAddedSong: action.NameOfAddedSong,
        };



    case "SET_TOKEN":
      return {
        ...state,
        token: action.token,
      };
//für avatar
    case "SET_SPOTIFY":
      return {
        ...state,
        spotify: action.spotify,
      };



      case "SET_SEARCHRESULTS":
        return {
          ...state,
          searchResults: action.searchResults,
        };

        case "SET_PLAYBLOCKER":
        return {
      ...state,
      playBlocker: action.playBlocker,
       };
        case "SET_COUNTUSERRATINGS":
          return {
            ...state,
            countUserRatings: action.countUserRatings,
          };

 
  


    case "SET_MENUSTATE":
      return {
        ...state,
        menustate: action.menustate,

      };

      case "SET_SUCHBEGRIFF":
        return {
          ...state,
          suchbegriff: action.suchbegriff,
  
        };

        case "SET_SUCHBEGRIFFKUENSTLER":
          return {
            ...state,
            suchbegriffkuenstler: action.suchbegriffkuenstler,
    
          };


        case "SET_SONGIWANTTOHAVEADDED":
        return {
          ...state,
          songIwantToHaveAdded: action.songIwantToHaveAdded,
  
        };
        case "SET_AUTOPLAYFOOTER":
        return {
          ...state,
          showAutoPlayFooter: action.showAutoPlayFooter,
  
        };

    

        case "SET_PACKAGEOFSONGSTORATE":
          return {
            ...state,
            packageOfSongsTorate: action.packageOfSongsTorate,
  
          }


          case "SET_PACKAGEOFRATINGSTODISPLAY":
            return {
              ...state,
              packageOfEntitledRatings: action.packageOfEntitledRatings,
    
            }

        // case "SET_MYPLAYLISTTOADDSONG":
        // return {
        //   ...state,
        //   myPlaylistToAddSong: action.myPlaylistToAddSong,

        // }

        // case "SET_SEARCHRESULTSPLAYLIST":
        //   return {

        //       ...state,
        //       searchResultsPlaylist: action.searchResultsPlaylist,

        //   };
          // case "SET_MYPLAYLISTTRACKS":
          //   return {
  
          //       ...state,
          //       setResultsPlaylistTracks: action.setResultsPlaylistTracks,
  
          //   };

            // case "SET_MYPLAYLISTIDFORTRACKS":
            //   return {
    
            //       ...state,
            //       myPlaylistIdForTracks: action.myPlaylistIdForTracks,
    
            //   };
              
              case "SET_SONGTOPLAY":
                return {
      
                    ...state,
                    songToPlay: action.songToPlay,
      
                };



                  case "SET_SONGGESETZT":
                    return {
          
                        ...state,
                        songGesetzt: action.songGesetzt,
          
                    };

                    case "SET_TIMEPERSONG":
                      return {
            
                          ...state,
                          timePerSong: action.timePerSong,
            
                      };


                      case "SET_ARTISTIDFORIMAGE":
                        return {
              
                            ...state,
                            artistIdForImage: action.artistIdForImage,
              
                        };
                        case "SET_ARTISTIDFORIMAGE2":
                          return {
                
                              ...state,
                              artistIdForImage2: action.artistIdForImage2,
                
                          };

                          case "SET_ARTISTIDFORIMAGE3":
                            return {
                  
                                ...state,
                                artistIdForImage3: action.artistIdForImage3,
                  
                            };


                            case "SET_ARTISTIDFORIMAGE4":
                              return {
                    
                                  ...state,
                                  artistIdForImage4: action.artistIdForImage4,
                    
                              };

                              case "SET_ARTISTIDFORIMAGE5":
                                return {
                      
                                    ...state,
                                    artistIdForImage5: action.artistIdForImage5,
                      
                                };

                                case "SET_ARTISTIDFORIMAGE6":
                                  return {
                        
                                      ...state,
                                      artistIdForImage6: action.artistIdForImage6,
                        
                                  };

                                  case "SET_ARTISTIDFORIMAGE7":
                                    return {
                          
                                        ...state,
                                        artistIdForImage7: action.artistIdForImage7,
                          
                                    };

                                    case "SET_ARTISTIDFORIMAGE8":
                                      return {
                            
                                          ...state,
                                          artistIdForImage8: action.artistIdForImage8,
                            
                                      };

                                      case "SET_ARTISTIDFORIMAGE9":
                                        return {
                              
                                            ...state,
                                            artistIdForImage9: action.artistIdForImage9,
                              
                                        };

                                        case "SET_ARTISTIDFORIMAGE10":
                                          return {
                                
                                              ...state,
                                              artistIdForImage10: action.artistIdForImage10,
                                
                                          };

                                          case "SET_ARTISTIDFORIMAGE11":
                                            return {
                                  
                                                ...state,
                                                artistIdForImage11: action.artistIdForImage11,
                                  
                                            };

                                            case "SET_ARTISTIDFORIMAGE12":
                                              return {
                                    
                                                  ...state,
                                                  artistIdForImage12: action.artistIdForImage12,
                                    
                                              };

                                              case "SET_ARTISTIDFORIMAGE13":
                                                return {
                                      
                                                    ...state,
                                                    artistIdForImage13: action.artistIdForImage13,
                                      
                                                };

                                                case "SET_ARTISTIDFORIMAGE14":
                                                  return {
                                        
                                                      ...state,
                                                      artistIdForImage14: action.artistIdForImage14,
                                        
                                                  };

                                                  case "SET_ARTISTIDFORIMAGE15":
                                                    return {
                                          
                                                        ...state,
                                                        artistIdForImage15: action.artistIdForImage15,
                                          
                                                    };

                                                    case "SET_ARTISTIDFORIMAGE16":
                                                      return {
                                            
                                                          ...state,
                                                          artistIdForImage16: action.artistIdForImage16,
                                            
                                                      };


                        case "SET_ARTISTIMAGESFORTIMER":
                          return {
                
                              ...state,
                              artistImageUrlTimer: action.artistImageUrlTimer,
                
                          };

                          case "SET_ARTISTIMAGESFORTIMER2":
                            return {
                  
                                ...state,
                                artistImageUrlTimer2: action.artistImageUrlTimer2,
                  
                            };

                            case "SET_ARTISTIMAGESFORTIMER3":
                              return {
                    
                                  ...state,
                                  artistImageUrlTimer3: action.artistImageUrlTimer3,
                    
                              };


                            case "SET_ARTISTIMAGESFORTIMER4":
                              return {
                    
                                  ...state,
                                  artistImageUrlTimer4: action.artistImageUrlTimer4,
                    
                              };


                            case "SET_ARTISTIMAGESFORTIMER5":
                              return {
                    
                                  ...state,
                                  artistImageUrlTimer5: action.artistImageUrlTimer5,
                    
                              };


                              case "SET_ARTISTIMAGESFORTIMER6":
                                return {
                      
                                    ...state,
                                    artistImageUrlTimer6: action.artistImageUrlTimer6,
                      
                                };

                                case "SET_ARTISTIMAGESFORTIMER7":
                                  return {
                        
                                      ...state,
                                      artistImageUrlTimer7: action.artistImageUrlTimer7,
                        
                                  };


                                  case "SET_ARTISTIMAGESFORTIMER8":
                                    return {
                          
                                        ...state,
                                        artistImageUrlTimer8: action.artistImageUrlTimer8,
                          
                                    };

                                    case "SET_ARTISTIMAGESFORTIMER9":
                                      return {
                            
                                          ...state,
                                          artistImageUrlTimer9: action.artistImageUrlTimer9,
                            
                                      };

                                      case "SET_ARTISTIMAGESFORTIMER10":
                                        return {
                              
                                            ...state,
                                            artistImageUrlTimer10: action.artistImageUrlTimer10,
                              
                                        };

                                        case "SET_ARTISTIMAGESFORTIMER11":
                                          return {
                                
                                              ...state,
                                              artistImageUrlTimer11: action.artistImageUrlTimer11,
                                
                                          };
                                          case "SET_ARTISTIMAGESFORTIMER12":
                                            return {
                                  
                                                ...state,
                                                artistImageUrlTimer12: action.artistImageUrlTimer12,
                                  
                                            };
       
                                            case "SET_ARTISTIMAGESFORTIMER13":
                                              return {
                                    
                                                  ...state,
                                                  artistImageUrlTimer13: action.artistImageUrlTimer13,
                                    
                                              };

                                              case "SET_ARTISTIMAGESFORTIMER14":
                                                return {
                                      
                                                    ...state,
                                                    artistImageUrlTimer14: action.artistImageUrlTimer14,
                                      
                                                };

                                                case "SET_ARTISTIMAGESFORTIMER15":
                                                  return {
                                        
                                                      ...state,
                                                      artistImageUrlTimer15: action.artistImageUrlTimer15,
                                        
                                                  };

                                                  case "SET_ARTISTIMAGESFORTIMER16":
                                                    return {
                                          
                                                        ...state,
                                                        artistImageUrlTimer16: action.artistImageUrlTimer16,
                                          
                                                    };


                                                    case "SET_ALBUMIMAGEFORTIMER":
                                                      return {
                                            
                                                          ...state,
                                                          albumImageUrlTimer: action.albumImageUrlTimer,
                                            
                                                      };
                            
                                                      case "SET_ALBUMIMAGEFORTIMER2":
                                                        return {
                                              
                                                            ...state,
                                                            albumImageUrlTimer2: action.albumImageUrlTimer2,
                                              
                                                        };
                            
                                                        case "SET_ALBUMIMAGEFORTIMER3":
                                                          return {
                                                
                                                              ...state,
                                                              albumImageUrlTimer3: action.albumImageUrlTimer3,
                                                
                                                          };
                            
                            
                                                        case "SET_ALBUMIMAGEFORTIMER4":
                                                          return {
                                                
                                                              ...state,
                                                              albumImageUrlTimer4: action.albumImageUrlTimer4,
                                                
                                                          };
                            
                            
                                                        case "SET_ALBUMIMAGEFORTIMER5":
                                                          return {
                                                
                                                              ...state,
                                                              albumImageUrlTimer5: action.albumImageUrlTimer5,
                                                
                                                          };
                            
                            
                                                          case "SET_ALBUMIMAGEFORTIMER6":
                                                            return {
                                                  
                                                                ...state,
                                                                albumImageUrlTimer6: action.albumImageUrlTimer6,
                                                  
                                                            };
                            
                                                            case "SET_ALBUMIMAGEFORTIMER7":
                                                              return {
                                                    
                                                                  ...state,
                                                                  albumImageUrlTimer7: action.albumImageUrlTimer7,
                                                    
                                                              };
                            
                            
                                                              case "SET_ALBUMIMAGEFORTIMER8":
                                                                return {
                                                      
                                                                    ...state,
                                                                    albumImageUrlTimer8: action.albumImageUrlTimer8,
                                                      
                                                                };
                            
                                                                case "SET_ALBUMIMAGEFORTIMER9":
                                                                  return {
                                                        
                                                                      ...state,
                                                                      albumImageUrlTimer9: action.albumImageUrlTimer9,
                                                        
                                                                  };
                            
                                                                  case "SET_ALBUMIMAGEFORTIMER10":
                                                                    return {
                                                          
                                                                        ...state,
                                                                        albumImageUrlTimer10: action.albumImageUrlTimer10,
                                                          
                                                                    };
                            
                                                                    case "SET_ALBUMIMAGEFORTIMER11":
                                                                      return {
                                                            
                                                                          ...state,
                                                                          albumImageUrlTimer11: action.albumImageUrlTimer11,
                                                            
                                                                      };
                                                                      case "SET_ALBUMIMAGEFORTIMER12":
                                                                        return {
                                                              
                                                                            ...state,
                                                                            albumImageUrlTimer12: action.albumImageUrlTimer12,
                                                              
                                                                        };
                                   
                                                                        case "SET_ALBUMIMAGEFORTIMER13":
                                                                          return {
                                                                
                                                                              ...state,
                                                                              albumImageUrlTimer13: action.albumImageUrlTimer13,
                                                                
                                                                          };
                            
                                                                          case "SET_ALBUMIMAGEFORTIMER14":
                                                                            return {
                                                                  
                                                                                ...state,
                                                                                albumImageUrlTimer14: action.albumImageUrlTimer14,
                                                                  
                                                                            };
                            
                                                                            case "SET_ALBUMIMAGEFORTIMER15":
                                                                              return {
                                                                    
                                                                                  ...state,
                                                                                  albumImageUrlTimer15: action.albumImageUrlTimer15,
                                                                    
                                                                              };
                            
                                                                              case "SET_ALBUMIMAGEFORTIMER16":
                                                                                return {
                                                                      
                                                                                    ...state,
                                                                                    albumImageUrlTimer16: action.albumImageUrlTimer16,
                                                                      
                                                                                };



                                //FUER TIMER                    

                            case "SET_ARTISTIMAGETOSHOW":
                              return {
                    
                                  ...state,
                                  artistImageToShow: action.artistImageToShow,
                    
                              };


                              case "SET_ALBUMIMAGETOSHOW":
                                return {
                      
                                    ...state,
                                    albumImageToShow: action.albumImageToShow,
                      
                                };


                              case "SET_SONGNAMETOSHOW":
                                return {
                      
                                    ...state,
                                    songNameToDisplay: action.songNameToDisplay,
                      
                                };

                                case "SET_ARTISTNAMETOSHOW":
                                  return {
                        
                                      ...state,
                                      artistNameToDisplay: action.artistNameToDisplay,
                        
                                  };
  
                                  // case "SET_ALBUMNAMEFORYOUBESLIDE":
                                  //   return {
                          
                                  //       ...state,
                                  //       albumNameForYoutubeSlide: action.albumNameForYoutubeSlide,
                          
                                  //   };
                                      
                                    
                          
                        
     //   case "SET_TRACKS":
       //   return {
         //   ...state,
           // tracks: action.tracks,
    
         // };
  
    default:
      return state;
  }
};

export default reducer;
