import React from 'react';
// import React, { useState, useEffect } from 'react';
import "./RatingsOfMySongBody.css";
import Header from "../Header";
import { useStateValue } from "../StateProvider";
import RatingBox from './RatingBox.js';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
//import { firestore, auth } from '../firebase';
import { firestore} from '../firebase';

function RatingsOfMySongBody({spotify}) {
  const [{ userstartseite}, dispatch] = useStateValue();



  const entitledRatingsRef = firestore.collection('entitledRatingsPerSongPerUser').where('fromspotifyuser', '==', userstartseite.id);
  const [entitledRatings] = useCollectionDataOnce(entitledRatingsRef);

  return (
    <div className="feedbackBody">
      <Header spotify={spotify} />
      <div>
        <h1>
          <p>✏️ Your Ratings</p>
         <div className="smallFont"> <p>Only you will see the ratings you receive.</p>
         <p><br/>Important: Rating section has been updated on June 12, 2023. Please re-add all your songs. Ratings that you received before June 12, 2023 can not be displayed anymore.</p></div>
         <h3>&nbsp;</h3>
        </h1>
        <h4>With every adding a song will receive 5 ratings.<br /><br />You can add several songs and/or the same song several times.</h4>
      </div>
      <div className="feedbackContainer">
        {entitledRatings && entitledRatings.map((rating) => (
          <RatingBox
            key={rating.id}
            userid={userstartseite.id} trackForBox={rating}
          />
        ))}
      </div>
    </div>
  );
}

export default RatingsOfMySongBody;
