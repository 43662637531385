import React, { useState, useEffect } from 'react';
import "./SongBox.css";
import SongImage from "./SongImage.js";

// import SongFromUser from "./SongFromUser.js";
// import SongBoxCountDown from "./SongBoxCountDown.js";
import SpotifyJam from "../../SpotifyJam";
import { useStateValue } from "../../StateProvider";

import CheatMSG from "../../CheatMSG";  
import SongBoxCountDownSponsored from './SongBoxCountDownSponsored';

import NoPlayerMSG from "../../NoPlayerMSG";


function SongBoxSponsored( {trackIdSponsored, spotify} ) {
    const [{isSpotAppOpen, playBlocker, token, infoSongPlaying, songToPlay, menustate}, dispatch] = useStateValue();

    


//INITIAL VALUE COUNTDOWN ZEIGEN
    const [showCounter, setShowCounter] = useState(
      //inital value
        0   


  ); //hook example



//TRTACKINFO ZUORDENBAR MACHEN
  const [trackInfo, setTrackInfo] = useState(
    //inital value
   
//     {

//     userID: null,
//     date: null,
//     time: null,
//     timestamp: "ddfdf ddfdf",
//     slots: 1,
//     appointmentType: null

// }

{

  album:  {
    "name" : "Can't Stop Raving",
  //  "id": "0tGPJ0bkWOUmH7MEOR77qc"
     "id" : "1hMjJNZT1jhuWNotbgTRsB"
  },
  artists: [

    {name: "Dune"},
    {artistid: "4u78tFtzvcrrs8kzkXsW4r"}

  ],

  

  id: "4Q7fEjeCZgU8oxjbLWrm7L",
  name: "Can‘t Stop Raving (Video Mix)",
  popularity: 54,
  tracknumber: 1,
  uri: "spotify:track:4Q7fEjeCZgU8oxjbLWrm7L"
  }


   





); //hook example




useEffect(() => {

    

    // GET TRACK INFO BASED ON TRACK ID
    SpotifyJam.getTrackInfo(trackIdSponsored, token).then((response) =>


    setTrackInfo( response )
    )


     

        
        
  }, [] );
 
// conditions im [] am schluss, leer bedeutet es lädt nur 1X wenn die App Komponente geladen wird


        
function playSong()
{


//AUTOPLAY FOOTER EINSCHALTEN
dispatch({
  type: "SET_AUTOPLAYFOOTER",
  showAutoPlayFooter: true,
   });


    // BENOETIGT FÜR CHEAT ÜBERWACHUNG!!
dispatch({
type: "SET_SONGTOPLAY",
songToPlay: trackIdSponsored,
 });

// SPOTIFY API END




dispatch({
  type: "SET_PLAYBLOCKER",
  playBlocker: 1,

});

//zeigt countdown? nach play
setShowCounter( 1 );

//damit bei jedem song überprüft wird ob spot. beendet
dispatch({
  
  type: "SET_CHECKIFSPOTCLOSED",
  checkIfSpotclosed: 0,

});


};


// AUF SPOTIFY PLAYER MSG WECHSELN WENN STATUS 404 - START

if (isSpotAppOpen.status == 404) {

  // alert ("Please finish listing to the current song:)"+"infoSongPlayingID = "+infoSongPlaying.item.id+" // "+" Box ID = "+songToPlay)
 
  dispatch({
    type: "SET_MENUSTATE",
    menustate: "noplayermessage",
  });

 if ( token && menustate==="noplayermessage") return <NoPlayerMSG />




};


//COUNTER NICHT ZEIGEN WENN ANDERER SONG GESPIELT WIRD ODER PAUSE GEDRUECKT WIRD

if (infoSongPlaying.item.id != "ischeating" && infoSongPlaying.is_playing===true  && infoSongPlaying.item.id != songToPlay || infoSongPlaying.item.id != "ischeating" && infoSongPlaying.is_playing===false) {

  // alert ("Please finish listing to the current song:)"+"infoSongPlayingID = "+infoSongPlaying.item.id+" // "+" Box ID = "+songToPlay)
 
  dispatch({
    type: "SET_MENUSTATE",
    menustate: "cheatmessage",
  });

 if ( token && menustate==="cheatmessage" ) return <CheatMSG spotify={spotify} />




};

//console.log(TrackForBox);

//2OHym1q6nzgtT75UCNjjro
// console.log(trackInfo);
  return (
     
  <div className="songBox">
<div className="albumImage">
{/* <SongImage AlbumId="1hMjJNZT1jhuWNotbgTRsB"/>  */}

{/* //WENN UNGÜLTIGE SONGID KOMMT COVER PUREMIAS FIRST SONG ALBUM ID=ERSTE KLAMMER */}
{(trackInfo.hasOwnProperty("error")===true) ?  (<h4><SongImage AlbumId="67EEM8KMmXq8qxQXdRpmXe"/></h4>) : (<SongImage AlbumId={trackInfo.album.id}/>)}


{/* <p>album id for image: {trackInfo.album.id}</p> */}

</div>



<div className="songRow__info">
{/* //WENN UNGÜLTIGE SONGID KOMMT kein Song beim Countdown, trotzdem Rating     KLAMMER IST SMILEY*/}
{(trackInfo.hasOwnProperty("error")===true) ?  (<p>Very quiet Artist - very Quiet song;)</p>) : (<p>{trackInfo.artists[0].name} - {trackInfo.name}</p>)}

</div>
{/* <p>{trackInfo.name}</p> */}

 {/* (ID: {TrackForBox.id}) */}
 
 {/* URL BILD: {albumImageUrl.images[0].url} */}


 {(playBlocker!=1) ? (
 <div className="playButtonSp" onClick={playSong}>▶️ PLAY and RATE</div>) : (<div className="waitUntil"><p>wait until currently playing is played and rated.</p></div>)}

 <div className="songRating">

  {/* FURTHER INFO SONG HIDE START */}
    
  {/* <p>DOCID:</p>
    {trackIdSponsored}
    <p>SONGID:</p>
    {trackIdSponsored} */}
    
     
  


{/* IF IN RETURN */}
{(showCounter===1)? (
     <SongBoxCountDownSponsored trackname={trackInfo.artists[0].name+"-"+trackInfo.name}/>
      ) : (
        <p>&nbsp;</p>
      //  <p>no counter: {showCounter}</p>
      )}







</div>




</div>
  ) 




}  
export default SongBoxSponsored;


