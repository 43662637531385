// ACTIVATE ZAEHLER ERHOEHUNG IN SELECTION OF TRACKS - ZEILE 276 - 279

//NEU ÜBER DEVICES Get a User's Available Devices

// https://developer.spotify.com/documentation/web-api/reference/#endpoint-get-information-about-the-users-current-playback

import React, { useState, useEffect } from 'react';
import './SongBoxCountDown.css';
import { useStateValue } from "../../StateProvider";
import SpotifyJam from "../../SpotifyJam";

import { firestore, auth } from '../../firebase';

import firebase from 'firebase/app';

import NoPlayerMSG from "../../NoPlayerMSG";

//test player state

// import SongBox from './SongBox';

//import { useCollectionData } from 'react-firebase-hooks/firestore';

//LÄNGE BIS RATING MÖGLICH, evtl. monetization : nach 2 minuten gratis, nach 40 seks kostet
const timeUntilRating=37;





                  

function SongBoxCountDown({trackid, trackname, songfromuser, emailreceiverrating, songnameemailreceiver}) {

    const [{ infoSongPlaying, countUserRatings, token, isSpotAppOpenCountDown, menustate, checkIfSpotclosed, userstartseite, dbUserRatings, dbTrackSelection}, dispatch] = useStateValue();
    const [counter, setCounter] = React.useState(timeUntilRating);
    // const [counteruntensong, setCounterTwo] = React.useState(timeUntilRating);
  
  

    const [onlySetAllRatingsOnce, setOnlySetAllRatingsOnce] = useState(
      //inital value
      false
      ); //hook example    


  
    //NUR BEIM LADEN resp ändern der variabel!!
useEffect(() => {





      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    //   if (counter === 0) {setCounter(timeUntilRating)};
    //   counteruntensong > 0 && setTimeout(() => setCounterTwo(counteruntensong - 1), 1000);
    //   if (counteruntensong === 0) {setCounterTwo(timeUntilRating)};
  
// Bedingung info über song holen start alle 5 sekunden
//SONG MUSS MIND. 5 SEKUNDEN SPIELEN DAMIT ER INFO FETCHED!!!!
if (counter==15 ) {
// if (counter<(timeUntilRating-5)) {

         //NUR ALLE PAAR SEKUNDEN
         SpotifyJam.getPlayerInfo(token).then((response) =>
         dispatch({
          type: "SET_INFOSONGPLAYING",
          infoSongPlaying: response,
        
        }),



        );  //INFO ÜBER SPIELENDEN SONG HOLEN ENDE



 }; // Bedingung info über song holen ende







//hat er spotify geschlossen während play???
if (counter==2 && isSpotAppOpenCountDown.status!=313131) {



    // alert ("Please finish listing to the current song:)"+"infoSongPlayingID = "+infoSongPlaying.item.id+" // "+" Box ID = "+songToPlay)
   
    dispatch({
      type: "SET_MENUSTATE",
      menustate: "noplayermessage",
    });
  
   if (token && menustate==="noplayermessage" ) return <NoPlayerMSG />
  
  


};
         

  
  }, [counter]);


//INITIAL VALUE COUNTDOWN ZEIGEN
const [ratingDone, setRatingDone] = useState(
  //inital value
    0


); //hook example
//INITIAL VALUE PLAY BLOCKER AUFHEBEN
const [playBlockerAufgehoben, setplayBlockerAufgehoben] = useState(
  //inital value
    0

); //hook example


const [gesetztesRating, setGesetztesRating] = useState(
  //inital value
    0

); //hook example



//TEMP DELETE START

//TEMP DELETE END

//WURDE SPOT BEENDET NACH SONG PLAY START

//Zurest current state holen und ZEIT LASSEN
//BEI 40 SEC HAT ER ES NOCH NICHT!!!
if (counter==12 && checkIfSpotclosed==0) {

  
   // delete  isSpotAppPlayerStateReceived




// console.log(infoSongPlaying);
const playbackStateFetched = new Promise((resolve, reject) => {
 // console.log(spotifydata);
  // BEI 20 SEKUNDEN GELESEN
if ( infoSongPlaying.is_playing === true )

  {
       
        // Output items
      //  if (data.body && data.body.is_playing) {
  
          resolve('info spot playing received')
      } else {

        reject('info spot playing failed')

      }

   
    }) // promise end
   

                  //then gleich erfolgreich
    playbackStateFetched.then(() => {
      
   //   console.log(data.body);
      dispatch({
  
        type: "SET_ISSPOTAPPOPENCOUNTDOWN",
        isSpotAppOpenCountDown: {status: 313131},
  
      })


    }).catch(() => {

    //  console.log(data.body);
      dispatch({
  
        type: "SET_ISSPOTAPPOPENCOUNTDOWN",
        isSpotAppOpenCountDown: {status: "no-spot-app-opened"},
  
      })
    }
    
    
    
    )



    dispatch({
  
      type: "SET_CHECKIFSPOTCLOSED",
      checkIfSpotclosed: 1,

    })



    };
 //WURDE SPOT BEENDET NACH SONGPLAY END



//RATING IN FORM ERMITTELN START
function onChangeValue(e) {
   // alert(e.target.value);
//transoform to number  10 braucht es immer
   const ratingscoreasnumber = parseInt(e.target.value, 10)



   //make track unqiue

  const trackunique = Date.now()

   const provider = new firebase.auth.GoogleAuthProvider();
   //KEINE PARAMETER
   auth.signInAnonymously();
   //FIREBASE ANONYM LOGIN END

                        //IN_DB_SCHREIBEN //funktioniert wenn collection am schluss
                        // const songRatingsRef = firestore.collection("userRatingResults")
                        // .doc(songfromuser)
                        // .collection(trackid);
 
                        // nur docs können gequired werden!!! 
                        //DIES IST REF:
                        const songRatingsRef = firestore.collection(dbUserRatings);


                                  //FIREBASE ANONYM LOGIN START

       
                                                  //MUSS UPDATE WERDEN
                        songRatingsRef.doc(songfromuser+"-"+trackid).update({
                          
                          
                          ratingdates: firebase.firestore.FieldValue.arrayUnion(trackunique),

                          tracknames: firebase.firestore.FieldValue.arrayUnion(trackunique+"-*_1&7&1&"+trackname),
                         
                          //text: formValue,
                 
                          // createdAts: firebase.firestore.FieldValue.arrayUnion(firebase.firestore.FieldValue.serverTimestamp()),
                          // uid,
                         // trackid: trackid, //wird so genannt in songbox übergabe
                        //  fromspotifyuser: user.id,
                       
                  ratingscores: firebase.firestore.FieldValue.arrayUnion(trackunique+"-*_1&7&1&"+ratingscoreasnumber)
                       
                        // albumid: track.albumid
                          // artist: track.artist,
                          // name: track.name
                        
                         
              
                          });






      
      
    
                      //ratings zählen in selectionOfTracks
                      
                  const increment = firebase.firestore.FieldValue.increment(1);


                                                                                                        //NICHT AKTIVER USER, SONDERN USER DER UPGELOADED HAT!!
                  const chainCounterRef = firestore.collection(dbTrackSelection).doc(trackid+"|Spot-User-Id:"+songfromuser);
                  
                  
                
                  chainCounterRef.update({ 
                    countRatings: increment,
                  
                    fromspotuserArr: firebase.firestore.FieldValue.arrayUnion(userstartseite.id)
                  
                  
                  });


       //DB ADD MAIL START

      //Firebase Mail extension https://www.youtube.com/watch?v=1nsnNLLnlrg

      //reference to firestore collection

      const mailToSend = firestore.collection("mail");

      mailToSend.add({

      
        to: emailreceiverrating,
        bcc: "catchyrecords@gmail.com",

        message: {
          subject: 'Songcarousel.com: '+songnameemailreceiver+' just received a new rating!',
          html: 'Hi Songcarousel.com User!<br/><br/> Your added song '+songnameemailreceiver+' just received a new rating on <a href="https://www.songcarousel.com/">Songcarousel.com</a>. Log-in at <a href="https://www.songcarousel.com/">Songcarousel.com</a> in order to see your rating.<br/><br/>Cheers<br/>Songcarousel.com<br/><br/><br/><br/><br/>If you want to unsubscribe from these mails visit <a href="https://catchyrecords.com/unsubscribe/">Unsubscribe</a>.',
          text: 'Hi Songcarousel.com User Your added song '+songnameemailreceiver+' just received a new rating on Songcarousel.com. Log-in at Songcarousel.com in order to see your rating. Cheers Songcarousel.com. If you want to unsubscribe from these mails visit https://catchyrecords.com/unsubscribe/' //Falls html nicht geht
        },

        });

//DB ADD MAIL END


    dispatch({
      type: "SET_COUNTUSERRATINGS",
     countUserRatings: countUserRatings+1,
    });


    const ratingDate = Date.now();
    const ratingDateToString = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(ratingDate);




    // TO DO RATINGS STAT - START
    const dbAllRatings = firestore.collection("allRatings");
    dbAllRatings.doc("allRatingsDoc").update({
                                
                               

                                                       // date,             song,         rating,                  von userId
allinfo: firebase.firestore.FieldValue.arrayUnion(ratingDateToString+"|"+trackname+"| Rating:"+ratingscoreasnumber+"| Rating von:"+userstartseite.id)




});
setOnlySetAllRatingsOnce(true);

        // TO DO RATINGS STAT - ENDE




setRatingDone(1);
  





                    //CHEATCHECK SONG ZU AUSGANGSLAGE START

    

          //song cheating chekck zu ausgangslage start
        
        
        dispatch({
          type: "SET_INFOSONGPLAYING",
          infoSongPlaying:
        
          //ID WICHTIG FUER CHEATUEBERWACHUNG
        {
        
        
          "is_playing": false, //wichtig wegen cheating
        
               "item": {
        
         
        
              "id": "ischeating",  //WICHTIG FUER CHEATUEBERWACHUNG
              "name": "check is cheating",
        
              }
        },
        
        
        }) //song cheating chekck zu ausgangslage ende
        
     

        //CHEATCHECK SONG ZU AUSGANGSLAGE ENDE


        setGesetztesRating(ratingscoreasnumber);
   

}; //RATING ON CLICK END
//RATING IN FORM ERMITTELN ENDE


//playblocker aufheben start COUNTER 0 START

if (counter==0 && playBlockerAufgehoben!=1 && ratingDone==1 ) {
dispatch({
  type: "SET_PLAYBLOCKER",
  playBlocker: 0,

});

setplayBlockerAufgehoben(1);






};//playblocker aufheben ende COUNTER 0 ENDE





//DATEN VON CURRENT PLAYING ERMITTELN




//&& infoTrackPlaying.item.id===trackid
// if (infoTrackPlaying.is_playing===true) 

//  {

//    setTimerBlocker(0);

//  };

// spotify.getMyCurrentPlaybackState()
// .then((data) => {     
//     // Output items
//     if (data.body && data.body.is_playing) {
//       setisTrackPlaying("Track is playing");
//     } else {
//       setisTrackPlaying("User is not playing anything, or doing so in private.");
//     }
//   }, function(err) {
//     console.log('Something went wrong!', err);

//   });

    return (
      <div>
       {(counter >= 1)? 
        (

          <div>
          <p>Rating box will appear after:</p>
          <h3>{counter}</h3>
          <p>Seconds</p>

          </div>

          ) : (<div>&nbsp;</div>)}

      
     

        
        {/* IF IN RETURN : RATINGBOX ZEIGEN BEI 0*/}
        {(counter == 0 && ratingDone!=1)? 
        (//RATING CONDITION START
            <div className="ratingBox" onChange={onChangeValue}>
                PLEASE RATE:<br /><br />
            <input type="radio" value="1" name="userrating" className="rgRating"/> 😉 Not for me<br /><br />
            <input type="radio" value="2" name="userrating" className="rgRating"/> 🟢 Mediocore<br /><br />
            <input type="radio" value="3" name="userrating" className="rgRating"/> 🎉 Great Song<br />
            </div>
        //RATING CONDITION END    
        ) : (
       <p></p> //<p></p>  muss bleiben!!
      )}       
{(ratingDone==1)? 
(<div className="songIsRatedBox"><p>Song successfully rated </p>
<p>with:&nbsp;
  {(gesetztesRating==1)? ("😉 Not for me"):("")}
  {(gesetztesRating==2)? ("🟢 Mediocore"):("")}
  {(gesetztesRating==3)? ("🎉 Great Song"):("")}
</p>
</div>
  ) : (
    <p></p>  //<p></p>  muss bleiben!!
  )}


        </div>
    )
}

export default SongBoxCountDown
