import React from 'react';

import './SearchBar.css';
import { useStateValue } from "../../StateProvider";
import SpotifyJam from "../../SpotifyJam";

//import SearchResults from '../SearchResults/SearchResults';

//hier kommt dann suchbegriff
function SearchFormNew() {
    const [{ suchbegriff, token}, dispatch] = useStateValue();

    function backToCarousel() {




      //CHEATCHECK SONG ZU AUSGANGSLAGE START
  
  
            dispatch({
              type: "SET_INFOSONGPLAYING",
              infoSongPlaying:
            
              //ID WICHTIG FUER CHEATUEBERWACHUNG
            {
            
            
              "is_playing": false, //wichtig wegen cheating
            
                   "item": {
            
             
            
                  "id": "ischeating",  //WICHTIG FUER CHEATUEBERWACHUNG
                  "name": "check is cheating",
            
                  }
            },
            
            
            }) //song cheating chekck zu ausgangslage ende
            
  
            //CHEATCHECK SONG ZU AUSGANGSLAGE ENDE
  
            dispatch({
                type: "SET_AUTOPLAYSONG",
                autoPlaySong: "false",
            })
  
            
            dispatch({
              type: "SET_PLAYBLOCKER",
              playBlocker: null,
  
            })
  
              //User Ratings auf Null
              dispatch({
                type: "SET_COUNTUSERRATINGS",
               countUserRatings: 0,
              });
     

              dispatch({
                type: "SET_DBLESENCOMINGMENU",
                dblesencomingfrommenu:1,
              });
      //LADEN DER SONG HINZUFÜGEN MASKE, WENN GENUG SONGS GERATED
     //ChooseSong.js wird geladen
        dispatch({
          type: "SET_MENUSTATE",
          menustate: "feedbackcarouselmenu",
        });
  
   //   token && menustate==="feedbackcarouselmenu" && <FeedbackCarousel spotify={spotify}/>
  
  
  
  }

//https://css-tricks.com/snippets/css/a-guide-to-flexbox/



   function searchSong() { // START FUNKTION SUCHE
 





    SpotifyJam.search(suchbegriff, token).then((response) =>

      dispatch({
        type: "SET_SEARCHRESULTS",
        searchResults: response,
        })
      );



    }; // ENDE FUNKTION SUCHE





    //SUHCHE AUFRUFEN



         //setzt suchbegriff auf das was von input kommt
       function handleTermChange(eventfreiwaehlbar) {

          dispatch({
            type: "SET_SUCHBEGRIFF",
            suchbegriff: eventfreiwaehlbar.target.value,
          });

  
      };








  return (



        <div className="SearchBar">
            
            {/* onChange heisst immer wenn jemand zu tippen beginnt wird handleTermChange aufgerufen */}

<input placeholder="Enter A Song or Artist" onChange={handleTermChange}/>

<button className="SearchButton" onClick={searchSong}>SEARCH</button>

{/* <input placeholder="Enter KUENSTLER" onChange={handleTermChangeThree}/> */}



{/* <input placeholder="Enter Album Name" onChange={handleTermChangeTwo}/> */}
{/* <button className="SearchButton" onClick={searchAlbum}>SEARCH ALBUM</button> */}


{/* <input placeholder="Enter Playlist ID or ALBUM ID" onChange={handleTermChangeTwo}/> */}
{/* <button className="SearchButton" onClick={getPlaylistIdSongs}>SONGS VON PLAYLIST ID</button> */}
{/* <button className="SearchButton" onClick={listAlbumIdSongs}>SONGS VON ALBUM ID</button> */}
<p>&nbsp;</p>
<p>&nbsp;</p>
<div onClick={backToCarousel} >
<h3><a className="takeMeBackSimple" href="#">I don't want to add a Song, take me Back to the Carousel</a></h3>
</div>
</div>
       



  );
}

export default SearchFormNew;





