


import React from "react";
import SpotifyPlayer from 'react-spotify-web-playback';

import { useStateValue } from "../StateProvider";

function FooterAutoplay() {
  const [{ token, songToPlay}, dispatch] = useStateValue();



    
//infos player https://github.com/gilbarbara/react-spotify-web-playback

 



   

  return (
    <div className="footer">
<SpotifyPlayer
  token={token}
//als array für alles von artist uris={['spotify:artist:6HQYnRM4OzToCYPpVBInuU']}
name={"Songcarousel Player"}
uris={`spotify:track:${songToPlay}`}
styles={{
  activeColor: '#fff',
  bgColor: '#fff',
  color: '#fff',
  loaderColor: '#fff',
  sliderColor: '#1cb954',
  trackArtistColor: '#797979',
  trackNameColor: '#000',
}}

autoPlay

/> 


    </div>
  );










}





export default FooterAutoplay;
