//import React from 'react';

export const SidebarDataForMenu = [
  {
    title: 'Carousel',
    menustatedeliver: 'feedbackcarouselmenu'
  },




  // {
  //   title: '2) Choose your Song Length and Song',
  //   menustatedeliver: 'choosesongmenu'
  // },


  {
    title: 'Ratings of my song',
    menustatedeliver:'ratingsofmysong'
  },



//   { 
//   title: 'Temp: test song adding',
//   menustatedeliver:'addownsong'
// },


// { 
//   title: 'Temp: SPONSOREDSLIDE',
//   menustatedeliver:'sponsoredslide'
// },

{ 
  title: 'FAQ',
  menustatedeliver:'faqslide'
},

// { 
//   title: 'Newsletter',
//   menustatedeliver:'newsletter'
// },




// { 
//   title: 'How it works',
//   menustatedeliver:'howitworks'
// }
];
