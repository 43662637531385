import React, { useState, useEffect } from 'react';
import SongBoxSponsored from "./SongBoxSponsored";
import "./SponsoredBoxes.css";

function ShowSponsoredBoxes({deliveredBoxes, spotify}) {

  
//Auswahl Auffüll Songs
                             //pool play it on repeat
    const auswahlSong1 = ["7jmZRq4KJcEC0Rqxr1hWaY"];

                             //edgy - i love your band    //gon -15min             // can't stop           //always now               //impossible               // still awake            //caught in a dream
 // const auswahlSong1 = ["556Gwxjv6xukdxSQ4Zq0Wt","772HAvTCVKmXcVx6lOhx56", "0x9GmQKRRAvqup2IgVgWRO", "71bshjUlN5v2QdCrQo0lYJ", "4fwPUq9kLGxJMDcHZ8BZy4", "5uPo1GwAaW8ufMLRt0No8X", "3FTwSyQYRks7YSAg2aBoHW"];


    const [randomSongIndex, setrandomSongIndex] = useState(
        //inital value
          null
     ); //hook example


     const [randomSongChosen, setrandomSongChosen] = useState(
        //inital value
          0
     ); //hook example


    useEffect(() => {

    

        setrandomSongIndex(
            Math.floor(Math.random()*auswahlSong1.length));
    
            setrandomSongChosen(
                1);
         
    
            
            
      }, [] );   



        //falls noch kein random song
      if(randomSongChosen==0) {

        return (
            <div>
                <p>Waiting for last song</p>
            </div>
        )

      }





      if(randomSongChosen==1) { // if randomSong chosenStart ÜBER GANZE FUNKTION
        const song1id = auswahlSong1[randomSongIndex]; //random von uswahlSong1
     

        

    const song2id = "3aqLiOCprnphFWFMFIGhAa"; // pool - load up
    const song3id = "5G8uTULucGHDzZvsVd8EDx"; // pool - in love
    const song4id = "5uPo1GwAaW8ufMLRt0No8X"; // pool - awake
    const song5id = "1AhM8TwqVqfc7HGqU5rebq"; // pool - 0815
    
    let trackList=[];

    //decide amount of sponsored boxes to show -> case says how many are delivered from firebase
    switch (deliveredBoxes) {
        case 5:
            trackList=[];
            break;
        case 4:
            trackList=[song1id];
            break;
        case 3:
            trackList=[song1id,song2id];
            break;
        case 2:
            trackList=[song1id,song2id,song3id];
            break;
        case 1:
            trackList=[song1id,song2id,song3id,song4id];
            break;
        case 0:
            trackList=[song1id,song2id,song3id,song4id,song5id];
            break;
        default:
            trackList=[song1id,song2id,song3id,song4id,song5id];
            break;
    }


 
 
 


    return (
        <div className="sponsoredBoxes">
    
     {/* <p>
   Boxes to show: {sponsoredBoxesToShow}
   </p>
   // For loop array */}
{
   trackList && trackList.map(tracknamehierdefiniert_proelement => {

  return <SongBoxSponsored trackIdSponsored={tracknamehierdefiniert_proelement} spotify={spotify}/>
 })


}
 
 
        </div>
    )
}

} // if randomSong chosenStart end

export default ShowSponsoredBoxes
