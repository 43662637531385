import React from "react";
import styled from "styled-components";

import CatchyLogoImg from "../../img/catchyrecords-star.png";

const LogoWrapper = styled.div `

display: flex;
align-items: center;
`;

const LogoImg = styled.div`

width: 29px;
height: 29px;
padding-left: 10px;
align-items: center;
img {

    width: 100%;
    height: 100%;
}


`;


const LogoText = styled.h2`
    font-size: 16px;
    margin: 0;
    margin-left: 4px;
    color: #222;
    font-weight: 500;
`;
 
export function Logo(props) {
    return <LogoWrapper>

        <LogoImg><img src={CatchyLogoImg} alt="Song Carousel" /></LogoImg>
        <LogoText>Songcarousel.com</LogoText>
    </LogoWrapper>


}