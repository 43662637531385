import React, { useState, useEffect } from 'react';

import "./DisplayRatingsOfSongBox.css";

// import "../FeedbackCarousel/FeedbackBody.css";
import RatingOfSongLine from './RatingOfSongLine';

import { useStateValue } from "../StateProvider";

import { firestore } from '../firebase';

// https://www.youtube.com/watch?v=4t2eHrFW_0M

function DisplayRatingsOfSongBox({ ratingInfo }) {
  const [{ dbUserRatings }] = useStateValue();

 // const [{ dbUserRatings }, dispatch] = useStateValue();

  const [ratingDataOfUserArray, setRatingDataOfUserArray] = useState(null);
  const [laengeEntRatings, setLaengeRatings] = useState(null); 

  useEffect(() => {
    const entitledRatingsRef = firestore.collection(dbUserRatings).where('ratingBoxIdentifier', '==', `${ratingInfo.fromspotifyuser}-${ratingInfo.trackIdOfSong}`);

    entitledRatingsRef.get().then(snapshot => {
      const data = snapshot.docs.map(doc => doc.data());
      setRatingDataOfUserArray(data);
    }).catch(error => {
      console.log('Error fetching ratings:', error);
    });
  }, [dbUserRatings, ratingInfo.fromspotifyuser, ratingInfo.trackIdOfSong]);

  useEffect(() => {
    if (ratingDataOfUserArray) {



    setLaengeRatings(ratingDataOfUserArray && ratingDataOfUserArray[0]?.ratingscores.length);
    

    
    }
  }, [ratingDataOfUserArray]);

  // const indexOfRating = 1;
  
  // console.log(ratingDataOfUserArray);







return (



  <div className="overallReturnDisplayRatingsOfSongBox">

    <div className="listOfSongRatingsMainTitle">
    <p>It already received {laengeEntRatings} rating(s):</p>
    </div>
  
    
    <div className="listOfSongRatings">
        {ratingDataOfUserArray && ratingDataOfUserArray.map((rating, index) => (
          <RatingOfSongLine
            key={index}
            ratingLineToDecipher={rating}
          />
        ))}
    </div>
  </div>
  );
}

export default DisplayRatingsOfSongBox;
