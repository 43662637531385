
import React, { useEffect, useState } from "react";
import SpotifyPlayer from 'react-spotify-web-playback';


import "./Footer.css";

import ImprintDataPrivacyContact  from "./ImprintDataPrivacyContact";

import { useStateValue } from "../StateProvider";



function Footer() {
  const [{ token, menustate, songToPlay, autoPlaySong }, dispatch] = useStateValue();



  useEffect(() => {
    //this is where the code runs once, wenn am schluss [] leer ist
   
      //every time a new post is added, this code is fired
    
  
    
    }, [] );


    function footerClick() {


      dispatch({
        type: "SET_MENUSTATE",
        menustate: "imprintdataprivacy",
      });

 if   (token && menustate==="imprintdataprivacy") return <ImprintDataPrivacyContact />
    }


 



  

  return (
    <div className="footer" onClick={footerClick}>

{/* <SpotifyPlayer
  token={token}
//als array für alles von artist uris={['spotify:artist:6HQYnRM4OzToCYPpVBInuU']}
name={"Songcarousel Player"}
uris={`spotify:track:${songToPlay}`}
styles={{
  activeColor: '#fff',
  bgColor: '#fff',
  color: '#fff',
  loaderColor: '#fff',
  sliderColor: '#1cb954',
  trackArtistColor: '#797979',
  trackNameColor: '#000',
}}


/>  */}


<a href="#">Imprint</a> | 
<a href="#">Privacy Policy</a> | 
<a href="#"> Contact</a> 
    </div>
  );





}





export default Footer;
