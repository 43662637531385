import React, { useState, useEffect } from 'react';
// import SpotifyWebApi from "spotify-web-api-js";
import "./SponsFeedbackBody.css";
// import Header from "../Header";
import { useStateValue } from "../StateProvider";
// import SongBox from "./SongBox";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { testTracksToGetRated } from "./testDataSelectionOfUnratedTracks";
import "../AddMySong/TrackResult.css";
import SpotifyJam from "../SpotifyJam";
// import PlaylistListTrackResults from "./PlaylistTrackResults"
// import { testTracksRatings } from "./testDataStoreRatings";
// import { testSongsAddedByUser } from "./testDataSongAddedByUser";
// import SongBoxSponsored from './SongBoxSponsored';
// import ChooseSong from "./ChooseSong";

// const s = new SpotifyWebApi(); //nötig für menuwechsel

//alles FireBase Import Start

// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';
//import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import FeedbackCarouselBodyUserFetched from "./SponsFeedbackCarouselBodyUserFetched";

//alles FireBase Import Ende


// //FIREBASE START
// firebase.initializeApp({

//   apiKey: "AIzaSyAxXVyeVFlxumGcAe8v9bDyy977ytFJTIg",
//   authDomain: "spot-feedback-carousel.firebaseapp.com",
//   projectId: "spot-feedback-carousel",
//   storageBucket: "spot-feedback-carousel.appspot.com",
//   messagingSenderId: "559838262486",
//   appId: "1:559838262486:web:4521526930b2aa99b3c577",
//   measurementId: "G-3WYBEEWDP7"


// })

//const auth = firebase.auth();
//const firestore = firebase.firestore();



// //FIREBASE STOPP



function FeedbackCarouselBody({spotify}) {
  const [{ menustate, countUserRatings, token, userstartseite, infoSongPlaying, getUserInfoOnce}, dispatch] = useStateValue();


  const [userIdFetched, setUserIdFetched] = useState(
    //inital value
   false
  
  ); //hook example


  //nur einmal beim laden
  useEffect(() => {



         


if (getUserInfoOnce===false) {

  //UserInfo rausholen
SpotifyJam.getUserInfo(token).then((response) =>



dispatch({
type: "SET_USERSTARTSEITE",
userstartseite: response,
}),

dispatch({
  type: "SET_GETUSERINFOONCE",
  getUserInfoOnce: true,
  })

); // UserInfo fertig


};
}, []);



// console.log(userstartseite);
//PROMISE Für USER ID
const waitForUserId = new Promise((resolve, reject) => {




  if (userstartseite.id!="nochnicht") {
    resolve()
  } else {

    reject('Failed reading user ID')
  }

})
//wird nur bei resolve gemacht
//! RETURN BASED ON PROMISE REACHED START
waitForUserId.then(() => {
  
  //  console.log([songsOfUser][0][0].id);

  setUserIdFetched(true);


//! RETURN BASED ON PROMISE REACHED END
//then end
}).catch((message) => {

  console.log('this is the catch ' + message);

});



return (

   

  <div className="carouselPreBody">

                {(userIdFetched===true)?
                
                (
                  <div>
                  
                  
<FeedbackCarouselBodyUserFetched spotify={spotify} userid={userstartseite.id}/>



</div>
)
: (<div><h4>Wait for User Info</h4></div>)

}
               
                  </div>
)}
export default FeedbackCarouselBody;















