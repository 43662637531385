// https://developer.spotify.com/documentation/web-playback-sdk/quick-start/#
export const authEndpoint = "https://accounts.spotify.com/authorize";
// Replace with your app's client ID, redirect URI and desired scopes


// *LOAD BALANCING NEU *
// BEI SABRYNA NEU https://developer.spotify.com/dashboard/applications/1668db4054d24ede8b1c1b0b2b3f3ba0
// BEI HANSI NEU: https://developer.spotify.com/dashboard/applications/ae00f23aec314de4910061d27117c2b5



                            //SABBY                              //hans sackstark
// const auswahlClientId = ["33060aba6dd7410a879d4ed1cebf7c92","6870a3d92eb048a7867bc9314b739409"];

                            //SABBY                              //hansi
const auswahlClientId = ["f1bf78af288844bab87f9972febb394f","ae00f23aec314de4910061d27117c2b5"];

let IndexForClientId = Math.floor(Math.random()*auswahlClientId.length);





// const clientSecret = "e9e32caa0b5d409a933d6ddec723081a"; braucht es nicht bei dieser methode

// const redirectUri = "https://spot-feedback-carousel.web.app/";

const redirectUri = "https://songcarousel.com/";
//const redirectUri = "http://localhost:3000/";


const scopes = [
  "user-read-currently-playing",
  // "user-read-recently-played",
  "user-read-playback-state",
  // "user-top-read",
  "user-modify-playback-state",
  // "playlist-read-private",
  // "playlist-read-collaborative",

  "user-library-read",
"user-library-modify",
  "streaming",
  "user-read-email",
  



  // BENÖTIGT FÜR SUCHE + WDK PLAYEER SIEHE https://github.com/gilbarbara/react-spotify-web-playback
  "user-read-private",

 



];

export const getTokenFromResponse = () => {
  return window.location.hash
    .substring(1)
    .split("&")
    .reduce((initial, item) => {
      var parts = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);

      return initial;
    }, {});
};

export const accessUrl = `${authEndpoint}?client_id=${auswahlClientId[IndexForClientId]}&redirect_uri=${redirectUri}&scope=${scopes.join(
  "%20"
)}&response_type=token&show_dialog=true`;


 