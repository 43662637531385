import React from 'react';

import './Track.css';
import { useStateValue } from "../StateProvider";
import "./TrackResult.css";




function TrackResult( {track} ) {
  const [{user}, dispatch] = useStateValue();


         //reference to firestore collection ! UM REINSCHREIBEN ZU KÖNNEN
// const tracksToRateRef = firestore.collection('selectionOfTracksTemp');





















                
                //alles bei then passiert nur bei sucess zeile 201
              
           
     
                
   

                
 
               








        return (
            <div className="Track">
            <div className="Track-information">
             <h3>{track.name}</h3>
        <p>{track.artist} | {track.album}</p>  

 
           <div className="trackResults" onClick={addSongAsMySongToAdd}>Submit this song!</div>

         
            </div>
 
            </div>

        )



    //hier für timer
        function addSongAsMySongToAdd() {




        
        // -- promise war  hier --
           





                  dispatch({
                    type: "SET_COUNTUSERRATINGS",
                    countUserRatings: 0,
                      });
                      
                      dispatch({
                        type: "SET_NAMEOFADDEDSONG",
                        NameOfAddedSong: track.artist +" - "+ track.name, 
                          });
        
        
                    dispatch({
                      type: "SET_AUTOPLAYSONG",
                      autoPlaySong: "false",
                    
                    });




                    dispatch({
                      type: "SET_FROMSPOTIFYUSERSONGTOADD",
                      fromSpotifyUserSongToAdd: user.id,
                        });

                        dispatch({
                          type: "SET_FROMSPOTIFYUSERSONGTOADDEMAIL",
                          fromSpotifyUserSongToAddEmail: user.email,
                            });  

                        dispatch({
                          type: "SET_TRACKIDSONGTOADD",
                          trackIdSongToAdd: track.id,
                            });
                            
                            dispatch({
                              type: "SET_SONGTOADDCHOSEN",
                              songToAddChosen: 1,
                                });






         //  setRatingsToAdd(currentRatings-5);

           // const { uid } = auth.currentUser;



    
            

   
//zu erhaltende ratings angleichen

// const decrement = firebase.firestore.FieldValue.increment(-5);

// // Update read count
// tracksToRateRef.doc(track.id).update({ countRatings: decrement });








            };//function addSongAsMySongToAdd end   




            
        
           
     
  
            
           
              
            






       
          
    










            


}

export default TrackResult;