import React, { useEffect } from 'react';

//import { useStateValue } from "../StateProvider";
//import SongImage from "../FeedbackCarousel/SongBoxes/SongImage";

function RatingOfSongLine({ ratingLineToDecipher }) {
  //  const [{datesOfRatingsFetched, ratingsOfSongFetched}, dispatch] = useStateValue();

//!!!!SHOWALL RATINGS (PAID) VS ONLYSHOWLATEST !!!_S T A R T 



  const isPayingUser=1; //0=only latest 1=all


//!!!!SHOWALL RATINGS (PAID) VS ONLYSHOWLATEST !!!_END


  // REVERSE
  function reverseArr(input) {
    var ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  let newDatesOfRatingsFetched = reverseArr(ratingLineToDecipher.ratingdates);
  let newRatingsOfSongFetched = reverseArr(ratingLineToDecipher.ratingscores);
  let newSongNameofSongFetched = reverseArr(ratingLineToDecipher.tracknames);

  //Wieviele Ratings gibt es? - START


let count=null;

  if (isPayingUser===1) {
 count = newRatingsOfSongFetched.length;

} else {
//FALLS NICHT ZAHLER

count = 1;
}


  //Wieviele Ratings gibt es? - END

  //Für jedes Element eine Aktion machen - START

        //alle rating Elemente
  const ratingElements = [];

  for (let i = 0; i < count; i++) {


    // CREATE DATE
    //indexofrating
    const ratingDate = newDatesOfRatingsFetched[i];
    const ratingDateToString = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(ratingDate);

    //CREATE SONG NAME
    //indexofrating
    const songNameCreated = newSongNameofSongFetched[i].split('-*_1&7&1&')[1];

    //CREATE RATING
    //indexofrating
    const RatingStrippedDown = newRatingsOfSongFetched[i].split('-*_1&7&1&')[1];


    //Ale in rating elements array schreiben
    ratingElements.push(
      <div key={i} className="ratingLine">
        <div>
          <strong>{ratingDateToString}</strong>
          
          :&nbsp;Your song&nbsp;
          {/* <strong> {songNameCreated} </strong> */}
          just received 1 play and the&nbsp;
          <strong>rating:&nbsp;</strong>
          {(RatingStrippedDown == 1) ? ("😉 Not for me") :
            (RatingStrippedDown == 2) ? ("🟢 Mediocore") :
              (RatingStrippedDown == 3) ? ("🎉 Great Song!") : ("leer")}
          <br />
        </div>
      </div>
    );
  }

  return <>{ratingElements}</>
  
  
  
}

export default RatingOfSongLine;
