import { Height } from "@material-ui/icons";
import { findByLabelText } from "@testing-library/react";
import React from "react";
import styled from "styled-components";
// import SidebarOption from "./SidebarOption";
import { SidebarDataForMenu } from "./SidebarDataForMenu";

import Link from "./Link";
 
const NavLinksContainer = styled.div`
 height: 100%;
 display: flex;
 align-items: center;

`;

const LinksWrapper = styled.ul `

margin: 0;
padding: 0;
display: flex;
height: 100%;
list-style: none;
background-color: rgb(240, 240, 240);

`;

//LINIEN OBEN NAVI BEI border-top: hier
const LinkItem = styled.li`
  height: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 18px;
  color: #1db954;
  align-items: center;
  justify-content: center;
  display: flex;
  border-top: 2px solid transparent;
  transition: all 220ms ease-in-out;


  &:hover {
    border-top: 2px solid #1db954;
    color: #1db954;
  }
`;

// const Link = styled.a `


// text-decoration: none;
// color: inherit;
// font-size: inherit;
// `;
export function NavLinks(props) {
 





    return (
    <NavLinksContainer>
        <LinksWrapper>

        {SidebarDataForMenu.map((item, index) => {


return <LinkItem><Link option={item.title} _menustate={item.menustatedeliver} > </Link></LinkItem>
 

})}


            {/* <LinkItem><Link href="#">Song Carousel</Link></LinkItem>
            <LinkItem><Link href="#">Rating of My Songs</Link></LinkItem>
            <LinkItem><Link href="#">How it works</Link></LinkItem>
            <LinkItem><Link href="#">FAQ</Link></LinkItem> */}
        </LinksWrapper>
    </NavLinksContainer>
    );




  }