import React from "react";
import Footer from "../FooterAndLinks/Footer";
import "../Player.css";
import RatingsOfMySongBody from "./RatingsOfMySongBody";

// import Footer from "./Footer";

function RatingsOfMySong({ spotify }) {




  return (
    <div className="player">
      <div className="player__body">
        
        <RatingsOfMySongBody spotify={spotify}/>
      </div>
      <Footer />
    </div>
  );



}
export default RatingsOfMySong;
